import React, { useContext, useEffect } from "react";
import AssignedLawFirms from "./components/AssignedLawFirms";
import {
  LawFirmsDispatchContext,
  LawFirmsStateContext,
} from "./context/LawFirmsProvider";
import { GenerateQueryParameterString } from "../../../../../../../../global/GenerateQueryParamString";
import useGetAssignedLawFirms from "./hooks/useGetAssignedLawFirms";
import DataContext from "../../../../../../../../../context/DataContext";
import {
  CONTENT_ADD_TEAMS,
  CONTENT_DELETE_CONFIRMATION,
  setfetchError,
  setfetchIsLoading,
  setFilterParamsList,
  setSelectedTeams,
  setShouldFetchData,
  setShowContent,
  showDeleteButton,
} from "./state/actions";
import Pagination from "./components/Pagination";
import { Button } from "@mui/material";
import LawFirmTeamsModal from "./components/LawFirmTeamsModal";
import { toggleDialog } from "../../../../../ClientDetail/FollowUps/CaseFollowUps/state/actions";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import { user } from "../../../../../../../../api/endpoints/user";
import PermissionWrapper from "../../../../../../../../global/PermissionWrapper";

const LawFirmTeamsWithState = ({ userId }) => {
  const state = useContext(LawFirmsStateContext);
  const dispatch = useContext(LawFirmsDispatchContext);
  const { userRoles, accessToken } = useContext(DataContext);

  const [fetchAssignedLawFirms] = useGetAssignedLawFirms(
    userId,
    state.filterString,
    accessToken,
    dispatch
  );

  useEffect(() => {
    let active = true;
    if (active) {
      let paramObj = {
        ...(state.resLimit ? { limit: state.resLimit } : {}),
        ...(state.offset ? { offset: state.offset } : {}),
        ...(state.orderingBy
          ? {
              ordering:
                state.orderingDirection === "asc"
                  ? state.orderingBy
                  : "-" + state.orderingBy,
            }
          : {}),
      };
      let filtStr = GenerateQueryParameterString(paramObj, false);
      setFilterParamsList(dispatch, filtStr);
      setShouldFetchData(dispatch, true);
    }
    return () => {
      active = false;
    };
  }, [
    dispatch,
    state.delayedSearchString,
    state.resLimit,
    state.orderingBy,
    state.orderingDirection,
    state.offset,
  ]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (state.shouldFetchData) {
        fetchAssignedLawFirms(
          userId,
          state.filterString,
          accessToken,
          dispatch
        );
      }
    }
    return () => {
      active = false;
    };
  }, [
    userId,
    state.shouldFetchData,
    state.filterString,
    accessToken,
    dispatch,
    fetchAssignedLawFirms,
  ]);

  const shouldShowDeleteButton = (isShowing) => {
    showDeleteButton(dispatch, isShowing);
  };

  const showDeleteConfirmation = () => {
    setShowContent(dispatch, CONTENT_DELETE_CONFIRMATION);
    toggleDialog(dispatch, true);
  };

  const showAddTeams = () => {
    setShowContent(dispatch, CONTENT_ADD_TEAMS);
    toggleDialog(dispatch, true);
  };

  const handleDelete = () => {
    toggleDialog(dispatch, false);

    let selectedTeamRelations = state.selectedTeams.map((item) => item.id);

    user
      .deleteUserFromLawFirmTeams(selectedTeamRelations, accessToken)
      .then((res) => {
        setShouldFetchData(dispatch, true);
        setSelectedTeams(dispatch, []);
        shouldShowDeleteButton(false);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(errArr);
        setfetchIsLoading(false);
      });
  };
  return (
    <div id="Law-Firm-Teams" data-testid="Law-Firm-Teams" className="my-2">
      <div className="flex items-center">
        <div className="w-[75%]">
          <span className="text-xl">Law Firm Team Assignment</span>
        </div>
        <div className="w-[25%] flex">
          <PermissionWrapper
            permission={userRoles.permissions.includes(
              "users.delete_teammember"
            )}
          >
            {state.showDeleteButton && (
              <Button
                className="m-2 capitalize"
                onClick={() => showDeleteConfirmation()}
                variant="contained"
                color="error"
              >
                Delete
              </Button>
            )}
          </PermissionWrapper>
          <PermissionWrapper
            permission={userRoles.permissions.includes("users.add_team")}
          >
            <Button
              className="m-2 capitalize"
              variant="outlined"
              onClick={() => showAddTeams()}
            >
              Add Team
            </Button>
          </PermissionWrapper>
        </div>
      </div>
      {state.data && state.data.length > 0 ? (
        <PermissionWrapper
          permission={userRoles.permissions.includes("users.view_team")}
        >
          <AssignedLawFirms showDeleteButton={shouldShowDeleteButton} />
          <Pagination />
        </PermissionWrapper>
      ) : (
        <div className="my-2 p-2 text-center border-solid border-2">
          No Assigned Law Firms
        </div>
      )}
      {state.showDialog && (
        <LawFirmTeamsModal
          selectedUserId={userId}
          handleDeleteTeams={handleDelete}
        />
      )}
    </div>
  );
};

export default LawFirmTeamsWithState;
