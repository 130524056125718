import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";
import LoadingIndicator from "../../../../../../../../global/LoadingIndicator";
import CapacityInputField from "./components/CapacityInputField";

const Capacities = ({ userId }) => {
  const { accessToken, userRoles } = useContext(DataContext);
  const [userCapacity, setUserCapacity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [capacityTrigger, setCapacityTrigger] = useState(false);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/user_capacity/?user=${userId}`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setUserCapacity(data);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        return err;
      });
  }, [userId, accessToken, params.id, capacityTrigger]);

  return (
    <div className="flex flex-col m-6">
      <h1 className="text-xl pb-1 ">Capacities</h1>
      <div className="w-full">
        {loading ? (
          <LoadingIndicator isLoading={loading} />
        ) : (
          <div
            style={{
              display: "grid",
              columnGap: 3,
              rowGap: 1,
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            {userCapacity.map((userCapacityItem, index) => (
              <CapacityInputField
                key={'capacityField_' + index}
                userId={userId}
                trigger={capacityTrigger}
                setTrigger={setCapacityTrigger}
                userCapacity={userCapacityItem}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Capacities;
