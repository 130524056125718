import {
  CONTENT_ADD_TEAMS,
  CURRENT_PAGE_SET,
  DIALOG_IS_TOUCHED,
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  ORDERING_BY_SET,
  ORDERING_DIR_SET,
  RESLIMIT_SET,
  RESULT_COUNT_SET,
  SET_FILTER_PARAM_LIST,
  SET_OFFSET,
  SET_SELECTED_TEAMS,
  SHOULD_FETCH_DATA,
  SHOW_CONTENT_SET,
  TOGGLE_DELETE_VISIBILITY,
  TOGGLE_DIALOG,
} from "./actions";

export const initialState = {
  shouldFetchData: true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  columns: [
    { display: "", field_name: "select_law_firm", type: "ClientColumn" },
    { display: "Law Firm", field_name: "name", type: "ClientColumn" },
    {
      display: "Law Firm Email",
      field_name: "email",
      type: "ClientColumn",
    },
    {
      display: "Law Firm Phone",
      field_name: "phone",
      type: "ClientColumn",
    },
    {
      display: "Law Firm Address",
      field_name: "address",
      type: "ClientColumn",
    },
  ],
  data: null,
  resLimit: 25,
  currentPage: 1,
  offset: 0,
  resultCount: { total: 0, current: 0 },
  orderingBy: "team__law_firm__name",
  orderingDirection: "asc",
  filterString: "",
  showDialog: false,
  showContent: CONTENT_ADD_TEAMS,
  selectedTeams: [],
  dialogIsTouched: false,
  showDeleteButton: false,
};

export function assignedLawFirmsReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return { ...state, shouldFetchData: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case RESLIMIT_SET: {
      return { ...state, resLimit: action.payload };
    }
    case RESULT_COUNT_SET: {
      return { ...state, resultCount: action.payload };
    }
    case SET_OFFSET: {
      return { ...state, offset: action.payload };
    }
    case CURRENT_PAGE_SET: {
      return { ...state, currentPage: action.payload };
    }
    case ORDERING_BY_SET: {
      return { ...state, orderingBy: action.payload };
    }
    case ORDERING_DIR_SET: {
      return { ...state, orderingDirection: action.payload };
    }
    case SET_FILTER_PARAM_LIST: {
      return { ...state, filterString: action.payload };
    }
    case TOGGLE_DIALOG: {
      return { ...state, showDialog: action.payload };
    }
    case SHOW_CONTENT_SET: {
      return { ...state, showContent: action.payload };
    }
    case SET_SELECTED_TEAMS: {
      return { ...state, selectedTeams: action.payload };
    }
    case DIALOG_IS_TOUCHED: {
      return { ...state, dialogIsTouched: action.payload };
    }
    case TOGGLE_DELETE_VISIBILITY: {
      return { ...state, showDeleteButton: action.payload };
    }
    default:
      return state;
  }
}
