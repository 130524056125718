import React, {useContext, useState } from "react";
import {DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import {Button, Dialog, TextField, Grid} from "@mui/material";
import { format } from "date-fns";
import {Close} from "@mui/icons-material";
import LoadingIndicator from "../../../../../../../../global/LoadingIndicator";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";


const AddUnavailabilityModal = ({userId, handleClose, setResponseState, setIsResponseModalVisible}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { accessToken, userRoles } = useContext(DataContext);

  const onSave = () => {
    if (!startDate) {
      setResponseState({
        message: "Please select both start and end dates.",
        isError: true,
      });
      setIsResponseModalVisible(true);
      return;
    }

    const postData = {
      start_date: startDate ? format(startDate, 'yyyy-MM-dd') : null,
      end_date: endDate ? format(endDate, 'yyyy-MM-dd') : null,
      user: userId,
    };

    setIsLoading(true);

    axios.post("/api/user_unavailability/", postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    })
      .then((response) => {
        setResponseState({
          message: "Unavailability has been successfully added.",
          isError: false,
        });
        setIsResponseModalVisible(true);
        handleClose();
        return response;
      })
      .catch((error) => {
        setResponseState({
          message: "Error saving unavailability. Please try again.",
          isError: true,
        });
        setIsResponseModalVisible(true);
        return false;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={true}>
        <div className="outer text-center p-2">
          <div className="text-right">
            <Button onClick={(e) => handleClose(e)}>
              <Close/>
            </Button>
          </div>

          <div className="text-center p-2 relative">
            <div className="text-center p-2">
              <div id="Content-Add-Users" data-testid="Content-Add-Users">
                <Grid container direction="column" spacing={4}>
                  <div className="text-xl text-center">Add Unavailability</div>

                  <Grid item xs={12}>
                    <DatePicker
                      disablePast
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      renderInput={(params) => <TextField fullWidth {...params} disabled={isLoading} />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      disablePast
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      renderInput={(params) => <TextField fullWidth {...params} disabled={isLoading} />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="m-2 capitalize"
                      variant="contained"
                      color="primary"
                      onClick={onSave}
                      disabled={isLoading || startDate == null}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            {isLoading && (
              <div className="inset-2 absolute flex items-center justify-center bg-white bg-opacity-75">
                <LoadingIndicator isLoading left="auto" />
              </div>
            )}
          </div>
        </div>

      </Dialog></LocalizationProvider>)
}

export default AddUnavailabilityModal;
