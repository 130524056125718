import { Close } from "@mui/icons-material";
import { Button, Dialog } from "@mui/material";
import React, { useContext } from "react";
import LoadingIndicator from "../../../../../../../../../global/LoadingIndicator";
import {
  CONTENT_ADD_TEAMS,
  CONTENT_DELETE_CONFIRMATION,
  setfetchError,
  setShouldFetchData,
} from "../state/actions";
import {
  LawFirmsDispatchContext,
  LawFirmsStateContext,
} from "../context/LawFirmsProvider";
import AddTeams from "./AddTeams";
import { toggleDialog } from "../../../../../../ClientDetail/FollowUps/CaseFollowUps/state/actions";
import DeleteConfirmation from "./DeleteConfirmation";

const LawFirmTeamsModal = ({ selectedUserId, handleDeleteTeams }) => {
  const state = useContext(LawFirmsStateContext);
  const dispatch = useContext(LawFirmsDispatchContext);

  const setUpDialogContent = (content) => {
    return getDialogContentUI(content);
  };

  const isConfirmed = (confirmed) => {
    if (confirmed) {
      handleDeleteTeams();
    }
  };

  const getDialogContentUI = (content) => {
    switch (content) {
      case CONTENT_ADD_TEAMS: {
        return (
          <AddTeams
            selectedUserId={selectedUserId}
            fetchNewData={fetchNewData}
            closeModal={handleClose}
          />
        );
      }
      case CONTENT_DELETE_CONFIRMATION: {
        return (
          <DeleteConfirmation
            closeModal={handleClose}
            setConfirmation={isConfirmed}
          />
        );
      }
      default: {
        return <LoadingIndicator isLoading={true} />;
      }
    }
  };

  const fetchNewData = () => {
    setShouldFetchData(dispatch, true);
  };

  const handleClose = () => {
    toggleDialog(dispatch, false);
    setfetchError(dispatch, false);
  };

  return (
    <Dialog open={state.showDialog}>
      <div className="outer text-center p-2">
        <div className="text-right">
          <Button onClick={(e) => handleClose(e)}>
            <Close />
          </Button>
        </div>

        <div className="text-center p-2">
          {state.dialogIsLoading ? (
            <LoadingIndicator isLoading={true} />
          ) : (
            <div
              className="text-center p-2"
              data-testid="AddTeams-Dialog-Content"
            >
              {setUpDialogContent(state.showContent)}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default LawFirmTeamsModal;
