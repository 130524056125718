import { useCallback } from "react";
import {
  setfetchError,
  setfetchInProgress,
  setfetchIsLoading,
  setfetchSuccess,
  setResultCount,
  setShouldFetchData,
} from "../state/actions";
import { user } from "../../../../../../../../../api/endpoints/user";
import ApiRequestErrorHandler from "../../../../../../../../../global/ApiRequestErrorHandler";

export default function useGetAssignedLawFirms(
  userId,
  queryParams,
  accessToken,
  dispatch
) {
  const fetchAssignedLawFirms = useCallback(() => {
    setfetchIsLoading(dispatch, true);
    setShouldFetchData(dispatch, false);

    user
      .getAssignedTeams(userId, queryParams, accessToken, dispatch)
      .then((res) => {
        setResultCount(dispatch, {
          total: res.count,
          current: res.results.length,
        });

        setfetchInProgress(dispatch, res.results);

        return res;
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(dispatch, errArr);
        setfetchIsLoading(dispatch, false);
      });
  }, [userId, queryParams, accessToken, dispatch]);

  return [fetchAssignedLawFirms];
}
