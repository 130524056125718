import { Button } from "@mui/material";
import React from "react";
import { CONTENT_ADD_TEAMS } from "../state/actions";

const DeleteConfirmation = ({ closeModal, setConfirmation }) => {
  const handleCancel = () => {
    closeModal();
  };
  const handleDelete = () => {
    setConfirmation(CONTENT_ADD_TEAMS);
  };
  return (
    <div
      id="Content-Confirm-Delete-Law-Firm-Teams"
      data-testid="Content-Confirm-Delete-Law-Firm-Teams"
    >
      <div className="text-xl text-center py-4">
        Are you sure you want to delete these records?
      </div>

      <Button
        className="m-2 capitalize border-solid-2 border-blue-500  hover:text-blue-500"
        variant="outlined"
        onClick={() => handleCancel()}
      >
        Cancel
      </Button>
      <Button
        className="m-2 capitalize bg-red-600 border-solid-2 border-red-600 text-white hover:text-red-600"
        onClick={() => handleDelete()}
      >
        Delete
      </Button>
    </div>
  );
};
export default DeleteConfirmation;
