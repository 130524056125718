import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ProfileInfo from "../../MyAccount/ProfileInfo";
import OrgPermissions from "../../MyAccount/OrgPermissions";
import Toolbar from "./Toolbar";
import PropTypes from 'prop-types';
import UserOptions from "../UserOptions";
import OverlayLoadingIndicator from "../../../../../global/Loading/OverlayLoadingIndicator";

export default function UserProfile({
  selectedUser,
  isDrawer=false,
  reloadTrigger,
  setReloadTrigger,
}) {
  const params = useParams();
  const { accessToken, userRoles, loggedInUser } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [managedLocations, setManagedLocations] = useState([]);

  useEffect(() => {
    if (
      loggedInUser.entity &&
      loggedInUser.entity.entity_type_label === "Provider"
    ) {
      axios
        .get(`/api/provider/?limit=100&managed_by=${loggedInUser.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setManagedLocations(response.data.results);
        });
    }
  }, [accessToken, loggedInUser, setManagedLocations]);

  useEffect(() => {
    if (!params.id && !userInfo) {
      setUserInfo(selectedUser);
    } else {
      setIsLoading(true);
      axios
        .get(`/api/users/${params.id || userInfo.pk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data;
          setUserInfo(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setErrorMessage("There was an error loading this content");
        });
    }
    //! userInfo CANNOT be in this dependency array
    // eslint-disable-next-line
  }, [
    accessToken,
    setUserInfo,
    trigger,
    setIsLoading,
    setErrorMessage,
    params,
    selectedUser,
  ]);

  return (
    <div className="min-h-full h-full xl:h-screen flex-1">
      <OverlayLoadingIndicator isLoading={isLoading} />
      {!userInfo && errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        <div className="w-full relative h-full flex min-h-screen">
          <div
            className={`flex w-full flex-col items-stretch min-h-screen ${
              isDrawer ? "xl:flex-col" : "xl:flex-row"
            }`}
          >
            <ProfileInfo
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              profile={true}
              isDrawer={isDrawer}
              reloadTrigger={reloadTrigger}
              setReloadTrigger={setReloadTrigger}
              managedLocations={managedLocations}
            />
            <div
              className={`min-h-full flex flex-col h-full flex-1 ${
                isDrawer ? "xl:w-full" : "xl:w-8/12"
              }`}
            >
              {userRoles.permissions.includes("users.change_user") ||
              userRoles.permissions.includes("users.delete_user") ? (
                <Toolbar
                  userInfo={userInfo}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  reloadTrigger={reloadTrigger}
                  setReloadTrigger={setReloadTrigger}
                  isDrawer={isDrawer}
                />
              ) : (
                ""
              )}
              <div className="flex-1 flex flex-col">
              <OrgPermissions
                userInfo={userInfo}
                trigger={trigger}
                setTrigger={setTrigger}
                profile={true}
                managedLocations={managedLocations}
              />
              <UserOptions
                userInfo={userInfo}
                trigger={trigger}
                setTrigger={setTrigger}
                myAccount={true}
              />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

UserProfile.propTypes = {
  selectedUser: PropTypes.object,
  isDrawer: PropTypes.any,
  reloadTrigger: PropTypes.any,
  setReloadTrigger: PropTypes.any,
};
