import InlineEditField from "./InlineEditField/InlineEditField";

export default function CapacityInputField({
  userId,
  trigger,
  setTrigger,
  userCapacity,
}) {
  const title = userCapacity["role_name"];
  const capacity = userCapacity ? userCapacity["capacity"] : null;
  const roleId = userCapacity["role_id"];

  return (
    <div id={`${title}-container`} className="p-1">
      <InlineEditField
        title={title}
        type={"text"}
        keyVal={"capacity"}
        displayDetail={userCapacity}
        apiUrl={`/api/user_capacity/`}
        displayNameKey={title}
        trigger={trigger}
        setTrigger={setTrigger}
        valueHistory={null}
        disabled={false}
        nullable={false}
        staticVal={capacity}
        activeWindow={userCapacity}
        roleId={roleId}
        user={userId}
        valueLabels={true}
      />
    </div>
  );
}
