import ApiServices from "../service";

export const user = {
  getGenderFieldOptions: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=user&field_name=gender&ordering=order`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getNamePrefixFieldOptions: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=user&field_name=name_prefix&ordering=order`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getManagedUsers: (accessToken, pagination = null) => {
    let paginate = pagination ? `${pagination}` : "";

    let query = paginate ? "?" : "";
    let deliminator = paginate ? "&" : "";
    return ApiServices.getData(
      `/api/users/managed_users/${query}${deliminator}${paginate}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },
  getAssignedTeams: (userId, queryParams, accessToken) =>
    ApiServices.getData(`api/team_members/?user=${userId}${queryParams}&ordering=law_firm_name`, accessToken),

  getLawFirmTeams: (queryParams, accessToken) =>
    ApiServices.getData(`api/teams/${queryParams}`, accessToken),

  getLawFirmTeamsUserIsNotAMemberOf: (userId, queryParams, accessToken) =>
    ApiServices.getData(`api/teams/?members_not__in=${userId}${queryParams}&ordering=law_firm_name`, accessToken),

  addUserToLawFirmTeams: (data, accessToken) =>
    ApiServices.postData(`api/team_members/`, data, accessToken),
  
  deleteUserFromLawFirmTeams:(data, accessToken) =>
    ApiServices.postData(`api/team_members/remove_team_members/`, data, accessToken),
};
