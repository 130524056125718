import React from "react";
import { Box } from "@mui/material";

export default function TabBodyContainer(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="flex-1 flex flex-col items-center"
    >
      {value === index && (
        <Box className="w-full flex-1 flex p-0">
          <div className="flex-1 overflow-y-auto w-full h-full"> {children}</div>
        </Box>
      )}
    </div>
  );
}
