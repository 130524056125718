import TabBodyContainer from "./TabBodyContainer";

export default function TabBody({ tabList, tabValue }) {
  return (
    <div className="bg-white py-0 h-full shadow-lg">
      {tabList.map((tab, index) => (
        <TabBodyContainer
          key={"tabpanel_" + index}
          value={tabValue}
          index={index}     
          className="h-full"
               
        >
          {tab.component}
        </TabBodyContainer>
      ))}
    </div>
  );
}
