import { CircularProgress } from "@mui/material";
import React from "react";

export default function OverlayLoadingIndicator({ isLoading }) {
    if (!isLoading) return null;

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50"
            style={{ zIndex: 100 }}
        >
            <CircularProgress color="secondary" />
        </div>
    );
}
