import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../../../../../../../global/TabPanel";
import LawFirmTeams from "./LawFirmTeams";
import Capacities from "./Capacities/Capacities";
import Unavailability from "./Unavailability/Unavailability";

const AutoAssignment = ({userId}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="Auto-Assignment" data-testid="Auto-Assignment" className="h-full">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="auto-assignment tabs"
        TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
        sx={{
          "& button.Mui-selected": {
            color: `#000`,
          },
          "& .Mui-selected": {
            color: `#9333EA`,
          },
          "& .MuiTab-root": {
            fontWeight:'700',
            color:'#000',
          },
          "& .MuiBox-root.div": {
            padding: 0,
          },
          "& .MuiTabs-fixed": {
            borderLeft:`1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
            borderRight:`1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
            borderBottom:`1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
          },
          "& .Mui-disabled": {
            color:'#ccc',
          },
        }}
      >
        <Tab label="Law Firm Teams" />
        <Tab label="Capacities" />
        <Tab label="Unavailability" disabled />
      </Tabs>
      <TabPanel value={value} index={0}  >
        <LawFirmTeams userId={userId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Capacities userId={userId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Unavailability userId={userId} />
      </TabPanel>
    </div>
  );
};

export default AutoAssignment;
