import { createContext, useMemo, useState } from "react";
const SearchContext = createContext({});

export const TOTAL_SETTLEMENT_AMOUNT_MIN = "actual_total_settlement_amount__gt";
export const TOTAL_SETTLEMENT_AMOUNT_MAX = "actual_total_settlement_amount__lt";
export const MEDICAL_LIMITS_AVAILABLE_MIN = "medical_limit__gt";
export const MEDICAL_LIMITS_AVAILABLE_MAX = "medical_limit__lt";
export const PROPERTY_DAMAGE_AMOUNT_MIN = "property_damage_amount__gt";
export const PROPERTY_DAMAGE_AMOUNT_MAX = "property_damage_amount__lt";
export const RUNNING_TOTAL_MIN = "running_total__gt";
export const RUNNING_TOTAL_MAX = "running_total__lt";

export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState("");
  const [archived, setArchived] = useState("false");
  const [progressionFinished, setProgressionFinished] = useState("");
  const [liability, setLiability] = useState("");
  const [medDocs, setMedDocs] = useState("");
  const [settled, setSettled] = useState("");
  const [npIntro, setNpIntro] = useState("");
  const [assignedCm, setAssignedCm] = useState("");
  const [onboarded, setOnboarded] = useState("");
  const [policeReport, setPoliceReport] = useState("");
  const [dateOfIncident, setDateOfIncident] = useState(null);
  const [accidentPictures, setAccidentPictures] = useState("");
  const [dateOpened, setDateOpened] = useState(null);
  const [propertyDamage, setPropertyDamage] = useState("");
  const [lostStatus, setLostStatus] = useState("");
  const [clientDob, setClientDob] = useState(null);
  const [involvedIndividuals, setInvolvedIndividuals] = useState();
  const [movingViolation, setMovingViolation] = useState("");
  const [clientManager, setClientManager] = useState(false);
  const [caseStatus, setCaseStatus] = useState("All");
  const [totalSettledAmount, setTotalSettledAmount] = useState("");
  const [selectedPM, setSelectedPM] = useState("");
  const [policyLimit, setPolicyLimit] = useState("");
  const [dateOpenedFrom, setDateOpenedFrom] = useState(null);
  const [dateOpenedTo, setDateOpenedTo] = useState(null);
  const [dateOfIncidentTo, setDateOfIncidentTo] = useState(null);
  const [dateOfIncidentFrom, setDateOfIncidentFrom] = useState(null);
  const [sortByLiabilityVal, setSortByLiabilityVal] = useState("");
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [noPnAssigned, setNoPnAssigned] = useState(null);
  const [noLfUserAssigned, setNoLfUserAssigned] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [hasCallback, setHasCallback] = useState(null);
  const [notLost, setNotLost] = useState(null);
  const [runningTotal, setRunningTotal] = useState("");

  const [selectedInvolved, setSelectedInvolved] = useState([]);
  const [activeInvolved, setActiveInvolved] = useState([]);

  const [selectedLawfirms, setSelectedLawfirms] = useState([]);
  const [activeLawFirm, setActiveLawFirm] = useState([]);

  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [activeStatuses, setActiveStatuses] = useState([]);

  const [selectedCaseStatuses, setSelectedCaseStatuses] = useState(['Active','Active - Waiting on Limits', 'Active - Client Unresponsive','Active - Paused (See Note)']);
  const [activeCaseStatuses, setActiveCaseStatuses] = useState(['Active','Active - Waiting on Limits', 'Active - Client Unresponsive','Active - Paused (See Note)']);

  const [selectedLegalStatuses, setSelectedLegalStatuses] = useState([]);
  const [activeLegalStatuses, setActiveLegalStatuses] = useState([]);

  const [selectedLost, setSelectedLost] = useState([]);
  const [activeLost, setActiveLost] = useState([]);

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [activeRoles, setActiveRoles] = useState([]);

  const [selectedUnassignedRoles, setSelectedUnassignedRoles] = useState([]);
  const [activeUnassignedRoles, setActiveUnassignedRoles] = useState([]);

  const [selectedSources, setSelectedSources] = useState([]);
  const [activeSources, setActiveSources] = useState([]);

  const [selectedDocTypes, setSelectedDocTypes] = useState([]);
  const [activeDocTypes, setActiveDocTypes] = useState([]);

  const [selectedNotUpDocTypes, setSelectedNotUpDocTypes] = useState([]);
  const [activeNotUpDocTypes, setActiveNotUpDocTypes] = useState([]);

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [activeEntities, setActiveEntities] = useState([]);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [activeLocations, setActiveLocations] = useState([]);

  const [selectedNotLocations, setSelectedNotLocations] = useState([]);
  const [activeNotLocations, setActiveNotLocations] = useState([]);

  const [hasSource, setHasSource] = useState(null);
  const [dateCreatedTo, setDateCreatedTo] = useState();
  const [dateCreatedFrom, setDateCreatedFrom] = useState();

  const [noteAddedTo, setNoteAddedTo] = useState();
  const [noteAddedFrom, setNoteAddedFrom] = useState();

  const [apptAddedTo, setApptAddedTo] = useState();
  const [apptAddedFrom, setApptAddedFrom] = useState();

  const [caseUpdatedTo, setCaseUpdatedTo] = useState();
  const [caseUpdatedFrom, setCaseUpdatedFrom] = useState();

  const [propertyDmgKey, setPropertyDmgKey] = useState(
    "property_damage_amount"
  );
  const [totalSettledAmtKey, setTotalSettledAmtKey] = useState(
    "actual_total_settlement_amount"
  );
  const [policyLimitKey, setPolicyLimitKey] = useState("policy_limit");
  const [runningTotalKey, setRunningTotalKey] = useState("running_total");

  const [needsSupIsNull, setNeedsSupIsNull] = useState(null);
  const [orderingVal, setOrderingVal] = useState(null);

  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [umStatus, setUmStatus] = useState("");
  const [pdSeverity, setPdSeverity] = useState("");
  const [pdSeverityIsNull, setPdSeverityIsNull] = useState(null);
  const [incidentLocationIsNull, setIncidentLocationIsNull] = useState(null);
  const [legal, setLegal] = useState("");
  const [overall, setOverall] = useState("Active");
  const [liabilityRequest, setLiabilityRequest] = useState("");

  // total settlement amount
  const [totalSettlementAmountMin, setTotalSettlementAmountMin] = useState(undefined);
  const [totalSettlementAmountMax, setTotalSettlementAmountMax] = useState(undefined);
  // medical limits available
  const [medicalLimitsAvailableMin, setMedicalLimitsAvailableMin] = useState(undefined);
  const [medicalLimitsAvailableMax, setMedicalLimitsAvailableMax] = useState(undefined);
  // property damage amount
  const [propertyDamageAmountMin, setPropertyDamageAmountMin] = useState(undefined);
  const [propertyDamageAmountMax, setPropertyDamageAmountMax] = useState(undefined);
  // running total
  const [runningTotalMin, setRunningTotalMin] = useState(undefined);
  const [runningTotalMax, setRunningTotalMax] = useState(undefined);

  return (
    <SearchContext.Provider
      value={useMemo(() => ({
        search,
        setSearch,
        archived,
        setArchived,
        progressionFinished,
        setProgressionFinished,
        liabilityRequest,
        setLiabilityRequest,
        overall,
        setOverall,
        legal,
        setLegal,
        liability,
        setLiability,
        medDocs,
        setMedDocs,
        settled,
        setSettled,
        npIntro,
        setNpIntro,
        assignedCm,
        setAssignedCm,
        onboarded,
        setOnboarded,
        policeReport,
        setPoliceReport,
        dateOfIncident,
        setDateOfIncident,
        accidentPictures,
        setAccidentPictures,
        dateOpened,
        setDateOpened,
        propertyDamage,
        setPropertyDamage,
        lostStatus,
        setLostStatus,
        clientDob,
        setClientDob,
        involvedIndividuals,
        setInvolvedIndividuals,
        movingViolation,
        setMovingViolation,
        clientManager,
        setClientManager,
        totalSettledAmount,
        setTotalSettledAmount,
        selectedPM,
        setSelectedPM,
        caseStatus,
        setCaseStatus,
        policyLimit,
        setPolicyLimit,
        dateOpenedFrom,
        setDateOpenedFrom,
        dateOpenedTo,
        setDateOpenedTo,
        dateOfIncidentTo,
        setDateOfIncidentTo,
        dateOfIncidentFrom,
        setDateOfIncidentFrom,
        sortByLiabilityVal,
        setSortByLiabilityVal,
        selectedUserType,
        setSelectedUserType,
        noLfUserAssigned,
        setNoLfUserAssigned,
        noPnAssigned,
        setNoPnAssigned,
        assignedUsers,
        setAssignedUsers,
        activeUsers,
        setActiveUsers,
        selectedInvolved,
        setSelectedInvolved,
        activeInvolved,
        setActiveInvolved,
        selectedSources,
        setSelectedSources,
        activeSources,
        setActiveSources,
        runningTotal,
        setRunningTotal,
        dateCreatedTo,
        setDateCreatedTo,
        dateCreatedFrom,
        setDateCreatedFrom,
        hasSource,
        setHasSource,
        selectedLawfirms,
        setSelectedLawfirms,
        activeLawFirm,
        setActiveLawFirm,
        hasCallback,
        setHasCallback,
        selectedLost,
        setSelectedLost,
        activeLost,
        setActiveLost,
        notLost,
        setNotLost,
        policyLimitKey,
        setPolicyLimitKey,
        propertyDmgKey,
        setPropertyDmgKey,
        runningTotalKey,
        setRunningTotalKey,
        totalSettledAmtKey,
        setTotalSettledAmtKey,
        selectedStatuses,
        setSelectedStatuses,
        activeStatuses,
        setActiveStatuses,
        needsSupIsNull,
        setNeedsSupIsNull,
        orderingVal,
        setOrderingVal,
        paginationUrl,
        setPaginationUrl,
        currentPage,
        setCurrentPage,
        isFirstLoad,
        setIsFirstLoad,
        umStatus,
        setUmStatus,
        pdSeverity,
        setPdSeverity,
        selectedRoles,
        setSelectedRoles,
        activeRoles,
        setActiveRoles,
        selectedUnassignedRoles,
        setSelectedUnassignedRoles,
        activeUnassignedRoles,
        setActiveUnassignedRoles,
        pdSeverityIsNull,
        setPdSeverityIsNull,
        selectedDocTypes,
        setSelectedDocTypes,
        activeDocTypes,
        setActiveDocTypes,
        selectedNotUpDocTypes,
        setSelectedNotUpDocTypes,
        activeNotUpDocTypes,
        setActiveNotUpDocTypes,
        caseUpdatedTo,
        setCaseUpdatedTo,
        caseUpdatedFrom,
        setCaseUpdatedFrom,
        selectedEntities,
        setSelectedEntities,
        activeEntities,
        setActiveEntities,
        noteAddedTo,
        setNoteAddedTo,
        noteAddedFrom,
        setNoteAddedFrom,
        apptAddedTo,
        setApptAddedTo,
        apptAddedFrom,
        setApptAddedFrom,
        selectedLocations,
        setSelectedLocations,
        activeLocations,
        setActiveLocations,
        selectedNotLocations,
        setSelectedNotLocations,
        activeNotLocations,
        setActiveNotLocations,
        incidentLocationIsNull,
        setIncidentLocationIsNull,
        // total settlement amount
        totalSettlementAmountMin,
        setTotalSettlementAmountMin,
        totalSettlementAmountMax,
        setTotalSettlementAmountMax,
        // medical limits available
        medicalLimitsAvailableMin,
        setMedicalLimitsAvailableMin,
        medicalLimitsAvailableMax,
        setMedicalLimitsAvailableMax,
        // property damage amount
        propertyDamageAmountMin,
        setPropertyDamageAmountMin,
        propertyDamageAmountMax,
        setPropertyDamageAmountMax,
        // running total
        runningTotalMin,
        setRunningTotalMin,
        runningTotalMax,
        setRunningTotalMax,
        selectedCaseStatuses,
        setSelectedCaseStatuses,
        activeCaseStatuses,
        setActiveCaseStatuses,
        selectedLegalStatuses,
        setSelectedLegalStatuses,
        activeLegalStatuses,
        setActiveLegalStatuses,
      }),
      [
        accidentPictures,
        activeDocTypes,
        activeEntities,
        activeInvolved,
        activeLawFirm,
        activeLocations,
        activeLost,
        activeNotLocations,
        activeNotUpDocTypes,
        activeRoles,
        activeSources,
        activeStatuses,
        activeUnassignedRoles,
        activeUsers,
        apptAddedFrom,
        apptAddedTo,
        archived,
        assignedCm,
        assignedUsers,
        caseStatus,
        caseUpdatedFrom,
        caseUpdatedTo,
        clientDob,
        clientManager,
        currentPage,
        dateCreatedFrom,
        dateCreatedTo,
        dateOfIncident,
        dateOfIncidentFrom,
        dateOfIncidentTo,
        dateOpened,
        dateOpenedFrom,
        dateOpenedTo,
        hasCallback,
        hasSource,
        incidentLocationIsNull,
        involvedIndividuals,
        isFirstLoad,
        legal,
        liability,
        liabilityRequest,
        lostStatus,
        medDocs,
        medicalLimitsAvailableMax,
        medicalLimitsAvailableMin,
        movingViolation,
        needsSupIsNull,
        noLfUserAssigned,
        noPnAssigned,
        notLost,
        noteAddedFrom,
        noteAddedTo,
        npIntro,
        onboarded,
        orderingVal,
        overall,
        paginationUrl,
        pdSeverity,
        pdSeverityIsNull,
        policeReport,
        policyLimit,
        policyLimitKey,
        progressionFinished,
        propertyDamage,
        propertyDamageAmountMax,
        propertyDamageAmountMin,
        propertyDmgKey,
        runningTotal,
        runningTotalKey,
        runningTotalMax,
        runningTotalMin,
        search,
        selectedDocTypes,
        selectedEntities,
        selectedInvolved,
        selectedLawfirms,
        selectedLocations,
        selectedLost,
        selectedNotLocations,
        selectedNotUpDocTypes,
        selectedPM,
        selectedRoles,
        selectedSources,
        selectedStatuses,
        selectedUnassignedRoles,
        selectedUserType,
        settled,
        sortByLiabilityVal,
        totalSettledAmount,
        totalSettledAmtKey,
        totalSettlementAmountMax,
        totalSettlementAmountMin,
        umStatus,
        selectedCaseStatuses,
        setSelectedCaseStatuses,
        activeCaseStatuses,
        setActiveCaseStatuses,
        selectedLegalStatuses,
        setSelectedLegalStatuses,
        activeLegalStatuses,
        setActiveLegalStatuses,
      ])}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
