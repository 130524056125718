import React, { useContext, useEffect, useState } from "react";
import ContactControls from "../../ContactControls";
import { Rating, Tooltip } from "@mui/material";
import { Warning } from "@mui/icons-material";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import ResponseModal from "../../ResponseModal";
import ProviderEmails from "../ProviderEmails";
import PermissionWrapper from "../../PermissionWrapper";
import ProviderInfoDisplayRow from "./ProviderInfoDisplayRow";
import { ENTITY_TYPE_PROVIDER } from "../../../../globalConstants";
import ProviderTypeMultiple from "../ProviderTypeMuiltiple";
import PropTypes from 'prop-types';
import ContactControlsMobile from "../../ContactControlsMobile";

export default function ProviderInformation({
  providerDetail,
  activeProvObj,
  parentTrigger,
  setParentTrigger
}) {
  const { userRoles, accessToken, loggedInUser } = useContext(DataContext);
  const [trigger, setTrigger] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const isProvider = loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;
  const [pickedOptions, setPickedOptions] = useState([]);
  const [provTypeOptions, setProvTypeOptions] = useState([]);
  useEffect(() => {
    if (Array.isArray(providerDetail.type)) {
      setPickedOptions(providerDetail.type);
    }
  }, [providerDetail.type])

  const handleRatingChange = (event, val) => {
    const patchData = {
      rating: val,
    };

    axios
      .patch(
        `/api/provider/${event.target.name}/`,
        patchData,

        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Provider Rating Updated");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  useEffect(
    () => {
      axios
        .get(`/api/field_options/?content_type=provider&ordering=order`, {
          headers: { Authorization: `Token ${accessToken}` }
        })
        .then(function(response) {
          setProvTypeOptions(response.data.results || []);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    [setProvTypeOptions, accessToken]
  );

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setTrigger(!trigger);
  };
  
  return (
    <div className="w-full">
      {" "}
      <div className="text-left">
        <h3 className="my-2">
          <strong>Name:</strong> {providerDetail.name}
        </h3>
        <h3>
          {providerDetail.in_network === true ? (
            <span className="text-purple-600">
              <strong>In-Network</strong>
            </span>
          ) : (
            <span className="text-red-700">
              <strong>Out-of-Network</strong>
            </span>
          )}
        </h3>
        {userRoles.permissions.includes("provider.view_provider") && (<ProviderTypeMultiple providerId={providerDetail.pk} options={provTypeOptions} pickedOptions={pickedOptions} setPickedOptions={setPickedOptions} trigger={parentTrigger} setTrigger={setParentTrigger}/>)}
        <h3 className="my-2">
          <strong>Address:</strong>{" "}
          {!providerDetail ? "" : providerDetail.address.raw}{" "}
        </h3>
        <ProviderInfoDisplayRow
          provider={providerDetail}
          trigger={parentTrigger}
          setTrigger={setParentTrigger}
          activeProvObj={activeProvObj}
          fieldLabel={"Email"}
          placeholder={
            "Please enter a valid email address. ex: example@example.com"
          }
          required={false}
        />
        <PermissionWrapper
          permission={userRoles.permissions.includes(
            "provider.view_provideremail"
          )}
        >
          <ProviderEmails
            providerDetail={providerDetail}
            activeProvObj={activeProvObj}
          />
        </PermissionWrapper>
        <div className="flex justify-between items-center">
          <ProviderInfoDisplayRow
            provider={providerDetail}
            trigger={parentTrigger}
            setTrigger={setParentTrigger}
            activeProvObj={activeProvObj}
            fieldLabel={"Phone"}
            placeholder={"Please enter a 10 digit phone number. ex: 0000000000"}
            required={true}
          />
          <ContactControlsMobile number={providerDetail.phone} />
        </div>
        <div className="flex justify-between items-center">
          <ProviderInfoDisplayRow
            provider={providerDetail}
            trigger={parentTrigger}
            setTrigger={setParentTrigger}
            activeProvObj={activeProvObj}
            fieldLabel={"Homepage"}
            placeholder={
              "Please enter web address starting with http:// or https://"
            }
            required={false}
          />
        </div>
        {!isProvider && userRoles.permissions.includes("provider.view_provider") &&
          <div className="flex items-center">
            <h3>
              <strong>Rating:</strong>
            </h3>
            <Rating
              name={`${providerDetail.pk}`}
              value={+providerDetail.rating}
              className="flex justify-center"
              disabled={
                !userRoles.permissions.includes("provider.change_provider")
              }
              onChange={(event, newValue) => {
                handleRatingChange(event, newValue);
              }}
            />
            {providerDetail.rating === null &&
              <Tooltip title="Not yet rated">
                <Warning style={{ color: "red" }} />
              </Tooltip>}
          </div>}
        <div className="my-2 border-purple-600 p-2 text-center border rounded-sm">
          <strong>Provider Summary:</strong> <p>{providerDetail.notes}</p>
        </div>
      </div>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}

ProviderInformation.propTypes = {
  providerDetail: PropTypes.shape({
    pk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.array.isRequired,
  }).isRequired,
  activeProvObj: PropTypes.object.isRequired,
  parentTrigger: PropTypes.bool.isRequired,
  setParentTrigger: PropTypes.func.isRequired
};