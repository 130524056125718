import {
  Button,
  Dialog,
  TextField
} from "@mui/material";
import {Close} from "@mui/icons-material";
import LoadingIndicator from "../../../../../../../global/LoadingIndicator";
import React, {useContext, useEffect, useState} from "react";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import {DataGrid} from "@mui/x-data-grid";
import axios from "../../../../../../../api/axios";
import DataContext from "../../../../../../../../context/DataContext";

const SelectedUsersGrid = ({selectedUserObjects}) => {
  return (
    <div className="flex flex-col max-h-[220px]">
      <h6 className="text-left text-xs text-gray-600 mb-2">Selected Users ({selectedUserObjects.length})</h6>

      {selectedUserObjects.length > 0 ? <><DataGrid
        rows={selectedUserObjects}
        columns={columns}
        hideFooter
        keepNonExistentRowsSelected
        disableColumnFilter
        disableColumnMenu
        getRowId={(row) => row.pk || row.id}
        columnHeaderHeight={0}
        maxRows={3}
        sortModel={[{field: 'name', sort: 'asc'}]}
      />
        <div className="border-b border-gray-300 my-4"></div></> : null}
    </div>
  );
};

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    sortable: false,
    filterable: false
  }
];

function generateErrorMessage(error) {
  let errorMessage = "Error saving user assignments";
  if (error?.response?.data?.detail.includes("must make a unique set")) {
    errorMessage = "User is already a Member, please refresh";
  }
  if (error?.response?.data?.detail.includes("To many records")) {
    errorMessage = error?.response?.data?.detail
  }
  return errorMessage;
}

const AddUserModal = ({
                        currentUserUserId,
                        isLoading = false,
                        handleClose,
                        managedUsers,
                        setTrigger,
                        setResponseState,
                        setIsResponseModalVisible
                      }) => {

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25
  });
  const [dataGridSelectionModel, setDataGridSelectionModel] = useState([]);
  const [selectedUserObjects, setSelectedUserObjects] = useState([]);
  const [currentFilterValue, setCurrentFilterValue] = useState("");
  const {accessToken, userRoles} = useContext(DataContext);
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const managedUserIds = new Set(managedUsers.map(relation => relation.user.pk));
  useEffect(() => {
    if (!userRoles.permissions.includes("users.view_user")) return;

    setLoading(true);
    const offset = paginationModel.page * paginationModel.pageSize;

    axios.get(
      `/api/users/all_assignable_users/?ordering=name&limit=${paginationModel.pageSize}&offset=${offset}${
        !currentFilterValue ? "" : `&search=${currentFilterValue}`
      }&exclude_managed=${currentUserUserId}`,
      {
        headers: {Authorization: `Token ${accessToken}`},
      }
    )
      .then((response) => {
        setAssignableUsers(response.data.results);
        setTotalRows(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

  }, [userRoles.permissions, currentFilterValue, accessToken, paginationModel, managedUsers]);

  const assignUserToManager = () => {
    const postData = selectedUserObjects.map(user => ({
      user: user.pk,
      managed_by: currentUserUserId,
    }));

    axios.post(
      `/api/user_manager/bulk_create/`,
      postData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      }
    )
      .then(() => {
        setTrigger(prev => !prev);
        setResponseState({
          description: "Users have been successfully added",
          isError: false
        });
        setIsResponseModalVisible(true);
        handleClose();
      })
      .catch((error) => {
        setResponseState({
          description: generateErrorMessage(error),
          isError: true
        });
        setIsResponseModalVisible(true);
      });
  };

  return (
    <Dialog open={true}>
      <div className="outer text-center p-2">
        <div className="text-right">
          <Button onClick={(e) => handleClose(e)}>
            <Close/>
          </Button>
        </div>

        <div className="text-center p-2">
          {isLoading ? (
            <LoadingIndicator isLoading={true}/>
          ) : (
            <div className="text-center p-2">
              <div id="Content-Add-Users" data-testid="Content-Add-Users">
                <div className="text-xl text-center">Add Managed Users</div>
                <TextField
                  className="placeholder:text-center rounded-lg w-full text-sm my-4"
                  placeholder={"User Search"}
                  size="small"
                  name="user-filter-search"
                  value={currentFilterValue}
                  InputLabelProps={{shrink: true}}
                  label="User Search"
                  onChange={(event) => {
                    const searchVal = event.target.value;
                    setCurrentFilterValue(searchVal);
                    setPaginationModel(prev => ({...prev, page: 0}));
                  }}
                />

                {!!selectedUserObjects.length && (
                  <SelectedUsersGrid selectedUserObjects={selectedUserObjects}/>
                )}

                <div style={{height: 400, width: '540px'}}>
                  <DataGrid
                    rows={assignableUsers}
                    columns={columns}
                    rowCount={totalRows}
                    loading={loading}
                    pageSizeOptions={[25]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    checkboxSelection
                    getRowId={(row) => row.pk || row.id}
                    keepNonExistentRowsSelected
                    columnHeaderHeight={0}
                    isRowSelectable={(params) => {
                      return !managedUserIds.has(params.id)
                    }}
                    onRowSelectionModelChange={(newSelection) => {
                      // Determine added and removed IDs
                      const addedIds = newSelection.filter(id => !dataGridSelectionModel.includes(id));
                      const removedIds = dataGridSelectionModel.filter(id => !newSelection.includes(id));

                      // Add newly selected users
                      const addedUsers = addedIds.map(id => assignableUsers.find(user => (user.pk || user.id) === id));
                      const updatedSelectedUsers = [...selectedUserObjects, ...addedUsers];

                      // Remove deselected users
                      const finalSelectedUsers = updatedSelectedUsers.filter(user => !removedIds.includes(user.pk || user.id));

                      setSelectedUserObjects(finalSelectedUsers);
                      setDataGridSelectionModel(newSelection);
                    }}
                    rowSelectionModel={dataGridSelectionModel}
                  />
                </div>

                <PermissionWrapper
                  permission={true}
                >
                  <Button
                    className="m-2 capitalize"
                    variant="contained"
                    color="primary"
                    onClick={assignUserToManager}
                    disabled={selectedUserObjects.length === 0 || loading }
                  >
                    Save
                  </Button>
                </PermissionWrapper>
              </div>
            </div>

          )}
        </div>
      </div>
    </Dialog>
  );
}

export default AddUserModal;
