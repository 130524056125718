export const SHOULD_FETCH_DATA = "SHOULD_FETCH_DATA";
export const FETCH_IS_LOADING = "FETCH_IS_LOADING";
export const FETCH_INPROGRESS = "FETCH_INPROGRESS";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_DONE = "FETCH_DONE";

export const RESLIMIT_SET = "RESLIMIT_SET";
export const RESULT_COUNT_SET = "RESULT_COUNT_SET";
export const SET_OFFSET = "SET_OFFSET";
export const CURRENT_PAGE_SET = "CURRENT_PAGE_SET";

export const ORDERING_BY_SET = "ORDERING_BY_SET";
export const ORDERING_DIR_SET = "ORDERING_DIR_SET";
export const SET_FILTER_PARAM_LIST = "SET_FILTER_PARAM_LIST";


export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const SHOW_CONTENT_SET = "SHOW_CONTENT_SET";
export const CONTENT_ADD_TEAMS = "CONTENT_ADD_TEAMS";
export const CONTENT_DELETE_CONFIRMATION = "CONTENT_DELETE_CONFIRMATION";
export const SET_SELECTED_TEAMS = "SET_SELECTED_TEAMS";
export const TOGGLE_DELETE_VISIBILITY = "TOGGLE_DELETE_VISIBILITY";


export const DIALOG_IS_TOUCHED = "DIALOG_IS_TOUCHED";

export const setShouldFetchData = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_DATA, payload: bool });

export const setfetchIsLoading = (dispatch, bool) =>
  dispatch({ type: FETCH_IS_LOADING, payload: bool });

export const setfetchInProgress = (dispatch, obj) =>
  dispatch({ type: FETCH_INPROGRESS, payload: obj });

export const setfetchSuccess = (dispatch, bool) =>
  dispatch({ type: FETCH_SUCCESS, payload: bool });

export const setfetchError = (dispatch, arr) =>
  dispatch({ type: FETCH_ERROR, payload: arr });

export const setfetchDone = (dispatch, bool) =>
  dispatch({ type: FETCH_DONE, payload: bool });

export const setResetLimit = (dispatch, num) =>
  dispatch({ type: RESLIMIT_SET, payload: num });

export const setResultCount = (dispatch, num) =>
  dispatch({ type: RESULT_COUNT_SET, payload: num });

export const setCurrentPage = (dispatch, num) =>
  dispatch({ type: CURRENT_PAGE_SET, payload: num });

export const setOrderingBy = (dispatch, str) =>
  dispatch({ type: ORDERING_BY_SET, payload: str });

export const setOrderingDirection = (dispatch, str) =>
  dispatch({ type: ORDERING_DIR_SET, payload: str });

export const setFilterParamsList = (dispatch, str) =>
  dispatch({ type: SET_FILTER_PARAM_LIST, payload: str });

export const setOffset = (dispatch, str) =>
  dispatch({ type: SET_OFFSET, payload: str });

export const showDeleteButton = (dispatch, bool) =>
  dispatch({type:TOGGLE_DELETE_VISIBILITY,  payload:bool});

export const setSelectedTeams = (dispatch, arr) =>
  dispatch({type:SET_SELECTED_TEAMS,  payload:arr});

export const setShowContent = (dispatch, str) =>
  dispatch({type:SHOW_CONTENT_SET, payload:str})
