import {Button} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { format } from 'date-fns';
import axios from "../../../../../../../../api/axios";
import { DataGrid } from '@mui/x-data-grid';
import ConfirmationModal from "../../../../../../../../global/Modal/ConfirmationModal";
import AddUnavailabilityModal from "./AddUnavailabilityModal";
import ResponseModal from "../../../../../../../../global/ResponseModal";
import DataContext from "../../../../../../../../../context/DataContext";

const columns = [
    { field: 'start_date', headerName: 'Start Date', flex: 1 },
    { field: 'end_date', headerName: 'End Date', flex: 1 }
];

const Unavailability = ({ userId }) => {
    const { accessToken } = useContext(DataContext);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [unavailabilityData, setUnavailabilityData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25
    });
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [responseState, setResponseState] = useState({
        message: "",
        isError: false
    });

    useEffect(() => {
        const fetchUnavailabilityData = async () => {
            if (!userId) return;

            setLoading(true);
            const offset = paginationModel.page * paginationModel.pageSize;
            const today = format(new Date(), 'yyyy-MM-dd');
            axios.get(`/api/user_unavailability/?offset=${offset}&end_date__gt=${today}`, {
                headers: { Authorization: `Token ${accessToken}` },
            })
            .then((response) => {
                setUnavailabilityData(response.data);
                return response.data;
            })
            .catch(() => {
                setResponseState({
                    message: "Failed to load unavailability data.",
                    isError: true,
                });
                return null;
            })
            .finally(() => {
                setLoading(false);
            });
        };

        fetchUnavailabilityData();
    }, [userId, accessToken, paginationModel, refreshKey]);

    const handleBulkDelete = () => {
        setLoading(true);
        axios.post('/api/user_unavailability/bulk_delete/', rowSelectionModel, {
            headers: { Authorization: `Token ${accessToken}` },
        })
        .then(() => {
            setResponseState({
                message: "Unavailability deleted successfully.",
                isError: false,
            });
            setRowSelectionModel([]);
            setShowConfirmDeleteModal(false);
            setRefreshKey(prevKey => prevKey + 1);
            return true;
        })
        .catch((error) => {
            setResponseState({
                message: "Failed to delete unavailability.",
                isError: true,
            });
            return false;
        })
        .finally(() => {
            setLoading(false);
            setIsResponseModalVisible(true);
        });
    }

    return <div
        id="Unavailability-Organization"
        data-testid="Unavailability-Organization"
        className="p-4"
    >
            <div className="flex items-center">
                <div className="w-[50%]">
                    <span className="text-xl">Unavailability</span>
                </div>
                <div className="w-[50%] flex justify-end">
                    {rowSelectionModel.length > 0 && (<Button
                        className="m-2 capitalize"
                        variant="contained"
                        color="error"
                        onClick={() => setShowConfirmDeleteModal(true)}
                    >
                        Delete
                    </Button>)}
                    <Button
                        className="m-2 capitalize"
                        variant="outlined"
                        onClick={() => setShowDialog(true)}
                    >
                        Add Unavailability
                    </Button>
                </div>
            </div>
            <DataGrid
                rows={unavailabilityData.results}
                loading={isLoading}
                rowHeight={50}
                columns={columns}
                disableColumnFilter
                disableColumnMenu
                checkboxSelection
                rowCount={unavailabilityData.count}
                localeText={{noRowsLabel: "No Unavailability for user"}}
                pageSizeOptions={[25]}
                pagination
                paginationMode="server"
                keepNonExistentRowsSelected
                sx={{ border: 0 }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                onPaginationModelChange={setPaginationModel}
                rowSelectionModel={rowSelectionModel}
                initialState={{pagination: {paginationModel}}}
            />


        {showDialog && (
        <AddUnavailabilityModal
          userId={userId}
          handleClose={() => {
              setShowDialog(false);
              setRefreshKey(prevKey => prevKey + 1);
          }}
          setIsResponseModalVisible={setIsResponseModalVisible}
          setResponseState={setResponseState}
        />)}

        <ConfirmationModal
          open={showConfirmDeleteModal}
          onClose={() => setShowConfirmDeleteModal(false)}
          onConfirm={handleBulkDelete}
          title="Confirm Delete"
          description="Are you sure you want to delete the selected user(s)?"
        />

        <ResponseModal
          title={responseState.isError ? "Failed" : "Successful"}
          isError={responseState.isError}
          description={responseState.message}
          errorMessage={responseState.message}
          openBool={isResponseModalVisible}
          setOpenBool={setIsResponseModalVisible}

        />
    </div>
};

export default Unavailability;
