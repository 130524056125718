import { Box, Button, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const ConfirmationModal = ({ 
  open, 
  onClose, 
  onConfirm, 
  title = "Confirm Delete",
  description = "Are you sure you want to delete these records?",
  confirmText = "Delete",
  cancelText = "Cancel"
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" alignItems="center" className="pb-2">
          <Typography id="confirmation-modal-title" variant="h6" component="h2" className="text-xl flex-grow text-center">
            {title}
          </Typography>
          <IconButton onClick={onClose} size="small" className="translate-x-4 -translate-y-4">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography id="confirmation-modal-description" className="text-center py-2">
          {description}
        </Typography>
        <Box className="p-4 flex justify-center">
          <Button
            className="capitalize border-solid-2 border-blue-500 hover:text-blue-500 mr-6"
            variant="outlined"
            onClick={onClose}
          >
            {cancelText}
          </Button>
          <Button
            className="capitalize bg-red-600 border-solid-2 border-red-600 text-white hover:text-red-600 hover:bg-gray-50"
            variant="contained"
            onClick={onConfirm}
            color="error"
          >
            {confirmText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
