import { Tabs, Box, Tab } from "@mui/material";

export default function TabHeader({ tabList, tabValue, handleChange }) {
  return (
    <Box
    className="justify-between rounded-tl-lg rounded-tr-lg"

      sx={{
        padding: "0",
        backgroundColor: "#f0f0f0"
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        className="font-bold"
        variant="fullWidth"
        TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
        sx={{
          "& button.Mui-selected": {
            color: `#9333EA`,
            backgroundColor: "#FFF",
            borderRadius:'10px 10px 0 0',
            border:`1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
            borderBottom:`none`,
          },
          "& .Mui-selected": {
            color: `#9333EA`,
            backgroundColor: "#FFF",
            borderRadius:'10px 10px 0 0'
          },
          "& .MuiTab-root": {
            borderBottom:`1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
            fontWeight:'700',
          },
          "& .MuiBox-root.div": {
            padding: 0,
            color:'#000',
          },
          "& .MuiTab-textColorPrimary":{
            color: "#000",
          },
          "& .Mui-disabled": {
            color:'#ccc',
          },
        }}
      >
        {tabList.map((tab, index) => {
          return (
            <Tab
              key={tab.id + '_' + index}
              label={tab.name}
              className={"w-1/2"}
              value={index}
              disabled={tab.isDisabled}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
