import { createContext, useReducer } from "react";
import {
  assignedLawFirmsReducer,
  initialState,
} from "../state/assignedLawFirmsReducer";

export const LawFirmsDispatchContext = createContext();
export const LawFirmsStateContext = createContext();

const LawFirmsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(assignedLawFirmsReducer, initialState);

  return (
    <LawFirmsDispatchContext.Provider value={dispatch}>
      <LawFirmsStateContext.Provider value={state}>
        {children}
      </LawFirmsStateContext.Provider>
    </LawFirmsDispatchContext.Provider>
  );
};

export default LawFirmsProvider;
