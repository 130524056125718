import { ArrowForwardIos } from "@mui/icons-material";
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material/";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import DataContext from "../../../../context/DataContext";
import SearchContext, {
  MEDICAL_LIMITS_AVAILABLE_MAX,
  MEDICAL_LIMITS_AVAILABLE_MIN,
  PROPERTY_DAMAGE_AMOUNT_MAX,
  PROPERTY_DAMAGE_AMOUNT_MIN,
  RUNNING_TOTAL_MAX,
  RUNNING_TOTAL_MIN,
  TOTAL_SETTLEMENT_AMOUNT_MAX,
  TOTAL_SETTLEMENT_AMOUNT_MIN,
} from "../../../../context/SearchContext";
import axios from "../../../api/axios";
import AccordionSelectForm from "../../../global/AccordionSelectForm";
import ClientListItemV2 from "../../../global/ClientListItemV2";
import DateRange from "../../../global/DateRange";
import PrintableClientList from "../../../global/PrintableClientList";
import SearchOptionGroup from "../../../global/SearchOptionGroup";
import PaginationControls from "../../../global/PaginationControls";
import OrderingArrow from "../../../global/OrderingArrow";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import { CustomChip } from "../../../global/CustomQueryChip";
import MultiSelectFilterV2 from "../../../global/MultiSelectFilterV2";
import MultiSelectSearchV2 from "../../../global/MultiSelectSearchV2";
import { ENTITY_TYPE_LAW_FIRM, ENTITY_TYPE_PROVIDER } from "../../../../globalConstants";
import ClientSearchRangeSlider from "./ClientSearchRangeSlider";
import MultiSelectFilterV3 from "../../../global/MultiSelectFilterV3";

const SLIDER_FILTER_KEYS = [
  TOTAL_SETTLEMENT_AMOUNT_MIN,
  TOTAL_SETTLEMENT_AMOUNT_MAX,
  MEDICAL_LIMITS_AVAILABLE_MIN,
  MEDICAL_LIMITS_AVAILABLE_MAX,
  PROPERTY_DAMAGE_AMOUNT_MIN,
  PROPERTY_DAMAGE_AMOUNT_MAX,
  RUNNING_TOTAL_MIN,
  RUNNING_TOTAL_MAX,
];

export default function ClientListSearchv3({ isDashboardVersion }) {
  const {
    accessToken,
    caseManagerPk,
    loggedInUser,
    trigger,
    setTrigger,
    userRoles,
    dashboardV,
  } = useContext(DataContext);

  const {
    search,
    setSearch,
    progressionFinished,
    setProgressionFinished,
    liabilityRequest,
    setLiabilityRequest,
    overall,
    setOverall,
    legal,
    setLegal,
    liability,
    setLiability,
    medDocs,
    setMedDocs,
    settled,
    setSettled,
    assignedCm,
    setAssignedCm,
    onboarded,
    setOnboarded,
    policeReport,
    setPoliceReport,
    dateOfIncident,
    setDateOfIncident,
    accidentPictures,
    setAccidentPictures,
    dateOpened,
    propertyDamage,
    setPropertyDamage,
    lostStatus,
    setLostStatus,
    clientDob,
    setClientDob,
    involvedIndividuals,
    setInvolvedIndividuals,
    clientManager,
    setClientManager,
    totalSettledAmount,
    setTotalSettledAmount,
    selectedPM,
    setSelectedPM,
    caseStatus,
    setCaseStatus,
    dateOpenedFrom,
    dateOpenedTo,
    dateOfIncidentTo,
    setDateOfIncidentTo,
    dateOfIncidentFrom,
    setDateOfIncidentFrom,
    noLfUserAssigned,
    setNoLfUserAssigned,
    assignedUsers,
    setAssignedUsers,
    activeUsers,
    setActiveUsers,
    selectedInvolved,
    setSelectedInvolved,
    activeInvolved,
    setActiveInvolved,
    selectedSources,
    setSelectedSources,
    activeSources,
    setActiveSources,
    sources,
    setSources,
    runningTotal,
    setRunningTotal,
    dateCreatedTo,
    setDateCreatedTo,
    dateCreatedFrom,
    setDateCreatedFrom,
    hasSource,
    setHasSource,
    selectedLawfirms,
    setSelectedLawfirms,
    activeLawFirm,
    setActiveLawFirm,
    hasCallback,
    setHasCallback,
    selectedLost,
    setSelectedLost,
    activeLost,
    setActiveLost,
    propertyDmgKey,
    setPropertyDmgKey,
    runningTotalKey,
    setRunningTotalKey,
    totalSettledAmtKey,
    setTotalSettledAmtKey,
    selectedStatuses,
    setSelectedStatuses,
    activeStatuses,
    setActiveStatuses,
    orderingVal,
    setOrderingVal,
    paginationUrl,
    setPaginationUrl,
    currentPage,
    setCurrentPage,
    pdSeverity,
    setPdSeverity,
    selectedRoles,
    setSelectedRoles,
    activeRoles,
    setActiveRoles,
    selectedUnassignedRoles,
    setSelectedUnassignedRoles,
    activeUnassignedRoles,
    setActiveUnassignedRoles,
    pdSeverityIsNull,
    setPdSeverityIsNull,
    selectedDocTypes,
    setSelectedDocTypes,
    activeDocTypes,
    setActiveDocTypes,
    selectedNotUpDocTypes,
    setSelectedNotUpDocTypes,
    activeNotUpDocTypes,
    setActiveNotUpDocTypes,
    caseUpdatedTo,
    setCaseUpdatedTo,
    caseUpdatedFrom,
    setCaseUpdatedFrom,
    selectedEntities,
    setSelectedEntities,
    activeEntities,
    setActiveEntities,
    noteAddedTo,
    setNoteAddedTo,
    noteAddedFrom,
    setNoteAddedFrom,
    apptAddedTo,
    setApptAddedTo,
    apptAddedFrom,
    setApptAddedFrom,
    selectedLocations,
    setSelectedLocations,
    activeLocations,
    setActiveLocations,
    selectedNotLocations,
    setSelectedNotLocations,
    activeNotLocations,
    setActiveNotLocations,
    totalSettlementAmountMin,
    setTotalSettlementAmountMin,
    totalSettlementAmountMax,
    setTotalSettlementAmountMax,
    medicalLimitsAvailableMin,
    setMedicalLimitsAvailableMin,
    medicalLimitsAvailableMax,
    setMedicalLimitsAvailableMax,
    propertyDamageAmountMin,
    setPropertyDamageAmountMin,
    propertyDamageAmountMax,
    setPropertyDamageAmountMax,
    runningTotalMin,
    setRunningTotalMin,
    runningTotalMax,
    setRunningTotalMax,
    selectedCaseStatuses,
    setSelectedCaseStatuses,
    activeCaseStatuses,
    setActiveCaseStatuses,
    selectedLegalStatuses,
    setSelectedLegalStatuses,
    activeLegalStatuses,
    setActiveLegalStatuses,
  } = useContext(SearchContext);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [toggleFilterMenu, setToggleFilterMenu] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [lawfirmNames, setLawfirmNames] = useState();
  const [lostNames, setLostNames] = useState();
  const [printableListCount, setPrintableListCount] = useState();
  const [printableList, setPrintableList] = useState();
  const [caseSourceObj, setCaseSourceObj] = useState();
  const [liabilityStatusChoices, setLiabilityStatusChoices] = useState([]);
  const [legalStatusChoices, setLegalStatusChoices] = useState([]);
  const [overallStatusChoices, setOverallStatusChoices] = useState([]);
  const [liabilityRequestStatusChoices, setLiabilityRequestStatusChoices] = useState([]);
  const [pdChoices, setPdChoices] = useState([]);
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [pdDisplayOptions, setPdDisplayOptions] = useState("");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `Clients List`,
  // });

  const menuOpenClose = () => {
    setToggleFilterMenu(!toggleFilterMenu);
  };

  // const printList = () => {
  //   setLoading(true);
  //   let querytest = qs.stringify(
  //     {
  //       archived: archived === "" ? null : archived,
  //       progression_finished:
  //         progressionFinished === "" ? null : progressionFinished,
  //       liability_status: liability === "" ? null : liability,
  //       all_medical_docs_recieved: medDocs === "" ? null : medDocs,
  //       settled: settled === "" ? null : settled,
  //       introduced_to_np: npIntro === "" ? null : npIntro,
  //       accident_pictures_received:
  //         accidentPictures === "" ? null : accidentPictures,
  //       onboarded: onboarded === "" ? null : onboarded,
  //       police_report_received: policeReport === "" ? null : policeReport,
  //       date_of_accident: !dateOfIncident
  //         ? null
  //         : new Date(dateOfIncident).toLocaleDateString("en-CA"),
  //       date_opened: !dateOpened
  //         ? null
  //         : new Date(dateOpened).toLocaleDateString("en-CA"),
  //       lost: !lostStatus ? null : lostStatus,
  //       [propertyDmgKey]: !propertyDamage ? null : propertyDamage,
  //       client__date_of_birth: !clientDob
  //         ? null
  //         : new Date(clientDob).toLocaleDateString("en-CA"),
  //       involved_individuals: !involvedIndividuals ? null : involvedIndividuals,
  //       search: !search ? null : search,
  //       moving_violation: !movingViolation ? null : movingViolation,
  //       client_manager__isnull: !clientManager ? null : clientManager,
  //       [totalSettledAmtKey]: !totalSettledAmount ? null : totalSettledAmount,
  //       [policyLimitKey]: !policyLimit ? null : policyLimit,
  //       [runningTotalKey]: !runningTotal ? null : runningTotal,
  //       status: caseStatus === "All" ? null : caseStatus,
  //       date_of_accident__gte: !dateOfIncidentFrom
  //         ? null
  //         : new Date(dateOfIncidentFrom).toLocaleDateString("en-CA"),
  //       date_of_accident__lte: !dateOfIncidentTo
  //         ? null
  //         : new Date(dateOfIncidentTo).toLocaleDateString("en-CA"),
  //       created_at__gte: !dateCreatedFrom
  //         ? null
  //         : new Date(dateCreatedFrom).toLocaleDateString("en-CA"),
  //       created_at__lte: !dateCreatedTo
  //         ? null
  //         : new Date(dateCreatedTo).toLocaleDateString("en-CA"),
  //       netpro_users_assigned: noPnAssigned === null ? null : noPnAssigned,
  //       has_law_firm_cm:
  //         noLfUserAssigned === null ? null : noLfUserAssigned,
  //       campaign__isnull: hasSource === null ? null : hasSource,
  //       callback_time__isnull: hasCallback === null ? null : hasCallback,
  //       lost__isnull: notLost === null ? null : notLost,
  //       supervisor_needed_reason__isnull:
  //         needsSupIsNull === null ? null : needsSupIsNull,
  //       um_status: !umStatus ? null : umStatus,
  //       property_damage_severity: !pdSeverity ? null : pdSeverity,
  //       ordering: !orderingVal ? null : orderingVal,
  //     },
  //     {
  //       skipNull: true,
  //     }
  //   );

  //   const assignedUserQuery =
  //     assignedUsers.length === 0 ? "" : `&assigned_users__in=${assignedUsers}`;
  //   const relatedCasesQuery =
  //     selectedInvolved.length === 0
  //       ? ""
  //       : `&related_cases__in=${selectedInvolved}`;
  //   const sourcesQuery =
  //     selectedSources.length === 0 ? "" : `&campaign__in=${selectedSources}`;
  //   const lawFirmQuery =
  //     selectedLawfirms.length === 0 ? "" : `&law_firm__in=${selectedLawfirms}`;
  //   const lostQuery =
  //     selectedLost.length === 0 ? "" : `&lost__in=${selectedLost}`;
  //   const statusQuery =
  //     selectedStatuses.length === 0 ? "" : `&status__in=${selectedStatuses}`;
  //   const roleQuery =
  //     selectedRoles.length === 0 ? "" : `&role_id__in=${selectedRoles}`;
  //   const unAssignedRoleQuery =
  //     selectedUnassignedRoles.length === 0
  //       ? ""
  //       : `&role_id_not__in=${selectedUnassignedRoles}`;

  //   axios
  //     .get(
  //       `/api/case/?limit=${printableListCount}&ordering=name&` +
  //         querytest +
  //         assignedUserQuery +
  //         relatedCasesQuery +
  //         lawFirmQuery +
  //         sourcesQuery +
  //         statusQuery +
  //         roleQuery +
  //         unAssignedRoleQuery +
  //         lostQuery,
  //       {
  //         headers: { Authorization: `Token ${accessToken}` },
  //       }
  //     )
  //     .then(function (response) {
  //       setPrintableList(response.data.results);
  //       setTimeout(() => {
  //         handlePrint();
  //         setLoading(false);
  //       }, "1000");
  //     })
  //     .catch(function (error) {});
  // };

  const sendRequest = () => {
    setLoading(true);

    let querytest = qs.stringify(
      {
        progression_finished:
          progressionFinished === "" ? null : progressionFinished,
        legal_status__in: activeLegalStatuses.length === 0 ? null : activeLegalStatuses.join(","),
        overall_status__in: activeCaseStatuses.length === 0 ? null : activeCaseStatuses.join(","),
        liability_request_status: liabilityRequest === "" ? null : liabilityRequest,
        liability_status: liability === "" ? null : liability,
        all_medical_docs_recieved: medDocs === "" ? null : medDocs,
        settled: settled === "" ? null : settled,
        accident_pictures_received:
          accidentPictures === "" ? null : accidentPictures,
        onboarded: onboarded === "" ? null : onboarded,
        police_report_received: policeReport === "" ? null : policeReport,
        date_of_accident: !dateOfIncident
          ? null
          : new Date(dateOfIncident).toLocaleDateString("en-CA"),
        date_opened: !dateOpened
          ? null
          : new Date(dateOpened).toLocaleDateString("en-CA"),
        lost: !lostStatus ? null : lostStatus,
        [propertyDmgKey]: !propertyDamage ? null : propertyDamage,
        client__date_of_birth: !clientDob
          ? null
          : new Date(clientDob).toLocaleDateString("en-CA"),
        involved_individuals: !involvedIndividuals ? null : involvedIndividuals,
        search: !search ? null : search,
        client_manager__isnull: !clientManager ? null : clientManager,
        [totalSettledAmtKey]: !totalSettledAmount ? null : totalSettledAmount,
        [runningTotalKey]: !runningTotal ? null : runningTotal,
        status__in: activeStatuses.length === 0? null : activeStatuses.join(","),
        date_of_accident__gte: !dateOfIncidentFrom
          ? null
          : new Date(dateOfIncidentFrom).toLocaleDateString("en-CA"),
        date_of_accident__lte: !dateOfIncidentTo
          ? null
          : new Date(dateOfIncidentTo).toLocaleDateString("en-CA"),
        created_at__gte: !dateCreatedFrom
          ? null
          : new Date(dateCreatedFrom).toLocaleDateString("en-CA"),
        created_at__lte: !dateCreatedTo
          ? null
          : new Date(dateCreatedTo).toLocaleDateString("en-CA"),
        updated_at__gte: !caseUpdatedFrom
          ? null
          : new Date(caseUpdatedFrom).toLocaleDateString("en-CA"),
        updated_at__lte: !caseUpdatedTo
          ? null
          : new Date(caseUpdatedTo).toLocaleDateString("en-CA"),
        last_note__gte: !noteAddedFrom
          ? null
          : new Date(noteAddedFrom).toLocaleDateString("en-CA"),
        last_note__lte: !noteAddedTo
          ? null
          : new Date(noteAddedTo).toLocaleDateString("en-CA"),
        last_appointment__gte: !apptAddedFrom
          ? null
          : new Date(apptAddedFrom).toLocaleDateString("en-CA"),
        last_appointment__lte: !apptAddedTo
          ? null
          : new Date(apptAddedTo).toLocaleDateString("en-CA"),
        has_law_firm_cm:
          noLfUserAssigned === null ? null : noLfUserAssigned,
        campaign__isnull: hasSource === null ? null : hasSource,
        callback_time__isnull: hasCallback === null ? null : hasCallback,
        property_damage_severity: !pdSeverity ? null : pdSeverity,
        ordering: !orderingVal ? null : orderingVal,
        property_damage_severity__isnull:
          pdSeverityIsNull === null ? null : pdSeverityIsNull,
        [TOTAL_SETTLEMENT_AMOUNT_MIN]: totalSettlementAmountMin,
        [TOTAL_SETTLEMENT_AMOUNT_MAX]: totalSettlementAmountMax,
        [MEDICAL_LIMITS_AVAILABLE_MIN]: medicalLimitsAvailableMin,
        [MEDICAL_LIMITS_AVAILABLE_MAX]: medicalLimitsAvailableMax,
        [PROPERTY_DAMAGE_AMOUNT_MIN]: propertyDamageAmountMin,
        [PROPERTY_DAMAGE_AMOUNT_MAX]: propertyDamageAmountMax,
        [RUNNING_TOTAL_MIN]: runningTotalMin,
        [RUNNING_TOTAL_MAX]: runningTotalMax,
      },
      {
        skipNull: true,
      }
    );

    const assignedUserQuery =
      assignedUsers.length === 0 ? "" : `&assigned_users__in=${assignedUsers}`;
    const relatedCasesQuery =
      selectedInvolved.length === 0
        ? ""
        : `&related_cases__in=${selectedInvolved}`;
    const sourcesQuery =
      selectedSources.length === 0 ? "" : `&campaign__in=${selectedSources}`;
    const lawFirmQuery =
      selectedLawfirms.length === 0 ? "" : `&law_firm__in=${selectedLawfirms}`;
    const lostQuery =
      selectedLost.length === 0 ? "" : `&lost__in=${selectedLost}`;
    const statusQuery =
      selectedStatuses.length === 0 ? "" : `&status__in=${selectedStatuses}`;
    const roleQuery =
      selectedRoles.length === 0 ? "" : `&role_id__in=${selectedRoles}`;
    const unAssignedRoleQuery =
      selectedUnassignedRoles.length === 0
        ? ""
        : `&role_id_not__in=${selectedUnassignedRoles}`;
    const documentUploadedTypeQuery =
      selectedDocTypes.length === 0 ? "" : `&doc_type__in=${selectedDocTypes}`;
    const documentNotUploadedTypeQuery =
      selectedNotUpDocTypes.length === 0
        ? ""
        : `&doc_type_not__in=${selectedNotUpDocTypes}`;
    const entitiesQuery =
      selectedEntities.length === 0 ? "" : `&entity__in=${selectedEntities}`;
    const locationsQuery =
      selectedLocations.length === 0
        ? ""
        : `&incident_location__in=${selectedLocations}`;
    const locationsNotQuery =
      selectedNotLocations.length === 0
        ? ""
        : `&incident_location__not_in=${selectedNotLocations}`;

    axios
      .get(
        `/api/case/?` +
        paginationUrl +
        querytest +
        assignedUserQuery +
        relatedCasesQuery +
        lawFirmQuery +
        sourcesQuery +
        statusQuery +
        roleQuery +
        unAssignedRoleQuery +
        documentUploadedTypeQuery +
        documentNotUploadedTypeQuery +
        entitiesQuery +
        locationsQuery +
        locationsNotQuery +
        lostQuery,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setPrintableListCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) { });

    querytest = qs.parse(querytest);
    setActiveFilters(Object.keys(querytest));
  };

  useDebouncedEffect(
    () => sendRequest(),
    [
      accessToken,
      progressionFinished,
      liabilityRequest,
      overall,
      legal,
      liability,
      medDocs,
      settled,
      accidentPictures,
      onboarded,
      policeReport,
      trigger,
      dateOfIncident,
      lostStatus,
      dateOpened,
      clientDob,
      clientManager,
      search,
      selectedPM,
      caseStatus,
      dateOpenedFrom,
      dateOpenedTo,
      dateOfIncidentFrom,
      dateOfIncidentTo,
      selectedLawfirms,
      selectedInvolved,
      selectedEntities,
      selectedSources,
      selectedLost,
      selectedStatuses,
      selectedUnassignedRoles,
      selectedRoles,
      selectedDocTypes,
      selectedNotUpDocTypes,
      selectedLocations,
      selectedNotLocations,
      noLfUserAssigned,
      assignedUsers,
      paginationUrl,
      setPaginationUrl,
      dateCreatedFrom,
      dateCreatedTo,
      caseUpdatedFrom,
      caseUpdatedTo,
      noteAddedTo,
      noteAddedFrom,
      apptAddedTo,
      apptAddedFrom,
      hasSource,
      hasCallback,
      pdSeverity,
      orderingVal,
      pdSeverityIsNull,
      selectedCaseStatuses,
      selectedLegalStatuses,
    ],
    250
  );

  useEffect(() => {
    if (userRoles.permissions.includes("lawyer.view_lawfirm")) {
      axios
        .get(`/api/lawfirm/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let lfObj = {};

          for (const item in data) {
            lfObj[data[item].pk] = data[item].name;
          }

          setLawfirmNames(lfObj);
        });
    }
    // "casemanager.view_lostcaseoptions"
    if (userRoles.permissions.includes("casemanager.view_lostcaseoptions")) {
      axios
        .get(`/api/lost_case_options/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;

          let lostObj = {};

          for (const item in data) {
            lostObj[data[item].id] = data[item].reason;
          }

          setLostNames(lostObj);
        });
    }

    if (userRoles.permissions.includes("utilities.view_fieldoptions")) {
      axios
        .get(
          `/api/field_options/?content_type=case&field_name=status&ordering=order`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          setCaseStatusOptions(response.data.results);
        });
    }

    const filterFieldData = (data, field) => {
      let filtered;
      filtered = data.filter((item) => item.field_name === field);
      return filtered;
    };
    const createDisplayObj = (data, field) => {
      let newObj = {};
      for (const item in data) {
        if (data[item].field_name === field) {
          newObj[data[item].key] = data[item].label;
        }
      }
      return newObj;
    };
    if (userRoles.permissions.includes("utilities.view_fieldoptions")) {
      Promise.all([
        axios
          .get(`/api/field_options/?content_type=case&ordering=order&limit=500`, {
            headers: { Authorization: `Token ${accessToken}` },
          }),
        axios
          .get(`/api/field_options/?content_type=clientcoverage&field_name=liability_request_status&ordering=order&limit=25`, {
            headers: { Authorization: `Token ${accessToken}` },
          })
      ])
        .then(([caseResponse, liabilityResponse]) => {
          let data = caseResponse.data.results;
          setLiabilityStatusChoices(filterFieldData(data, "liability_status"));
          setLegalStatusChoices(filterFieldData(data, "legal_status"));
          setOverallStatusChoices(filterFieldData(data, "overall_status"));
          setPdChoices(filterFieldData(data, "property_damage_severity"));
          setPdDisplayOptions(
            createDisplayObj(
              filterFieldData(data, "property_damage_severity"),
              "property_damage_severity"
            )
          );
          setLiabilityRequestStatusChoices(liabilityResponse.data.results);
        })
    } else {
      return;
    }

    // eslint-disable-next-line
  }, [caseManagerPk]);

  useEffect(() => {
    if (isDashboardVersion) {
      setToggleFilterMenu(true);
    }
    // eslint-disable-next-line
  }, []);

  const isProvider = loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;
  const isLawFirm = loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_LAW_FIRM;

  const getColumnClass = (isProvider, isLawFirm, size) => {
    const classes = {
      small: {
        lawFirm: "w-[13.5%]",
        provider: "w-[13.6%]",
        default: "w-[12%]"
      },
      lg: {
        lawFirm: "w-[19%]",
        provider: "w-[17%]",
        default: "w-[15%]"
      },
      medium: {
        lawFirm: "w-[13%]",
        provider: "w-[15%]",
        default: "w-[15%]"
      }
    };
  
    if (isLawFirm) return classes[size].lawFirm;
    if (isProvider) return classes[size].provider;
    
    return classes[size].default;
  };
  
  const columnClasses = useMemo(() => getColumnClass(isProvider, isLawFirm, 'medium'), [isProvider, isLawFirm]);
  const columnClassesSmall = useMemo(() => getColumnClass(isProvider, isLawFirm, 'small'), [isProvider, isLawFirm]);
  const columnClassesLg = useMemo(() => getColumnClass(isProvider, isLawFirm, 'lg'), [isProvider, isLawFirm]);

  const filterBgColor = (val) => {
    if (val === "moving_violation") {
      return "bg-orange-400";
    } else if (
      val === "progression_finished" &&
      progressionFinished === "true"
    ) {
      return "bg-purple-400 text-white";
    } else if (
      val === "progression_finished" &&
      progressionFinished === "false"
    ) {
      return "bg-amber-400 text-white";
    } else if (val === "liability_request_status" && liabilityRequest === "true") {
      return "bg-sky-400 text-white";
    } else if (val === "actual_case_status" && overall === "true") {
      return "bg-sky-400 text-white";
    } else if (val === "legal_status" && legal === "true") {
      return "bg-sky-400 text-white";
    } else if (val === "liability_status" && liability === "true") {
      return "bg-sky-400 text-white";
    } else if (val === "all_medical_docs_recieved" && medDocs === "true") {
      return "bg-purple-400 text-white";
    } else if (val === "all_medical_docs_recieved" && medDocs === "false") {
      return "bg-red-400 text-white";
    } else if (val === "settled" && settled === "true") {
      return "bg-sky-400 text-white";
    } else if (
      val === "accident_pictures_received" &&
      accidentPictures === "true"
    ) {
      return "bg-sky-400 text-white";
    } else if (
      val === "accident_pictures_received" &&
      accidentPictures === "false"
    ) {
      return "bg-red-400 text-white";
    } else if (val === "assigned_case_manager") {
      return "bg-gray-600 text-white";
    } else if (val === "onboarded" && onboarded === "true") {
      return "bg-purple-600 text-white";
    } else if (val === "onboarded" && onboarded === "false") {
      return "bg-red-600 text-white";
    } else if (val === "police_report_received" && policeReport === "true") {
      return "bg-sky-600 text-white";
    } else if (val === "police_report_received" && policeReport === "false") {
      return "bg-red-600 text-white";
    } else if (val === "date_of_accident") {
      return "bg-yellow-600 text-white";
    } else if (val === "date_opened") {
      return "bg-yellow-600 text-white";
    } else if (val === "lost") {
      return "bg-red-600 text-white";
    } else if (val === propertyDmgKey || val === PROPERTY_DAMAGE_AMOUNT_MIN) {
      return "bg-orange-600 text-white";
    } else if (val === "client__date_of_birth") {
      return "bg-yellow-600 text-white";
    } else if (val === "search") {
      return "bg-green-600 text-white";
    } else if (val === "client_manager__isnull") {
      return "bg-green-600 text-white";
    } else if (val === "assigned_users") {
      return "bg-purple-600 text-white";
    } else if (val === MEDICAL_LIMITS_AVAILABLE_MIN) {
      return "bg-sky-600 text-white";
    } else if (val === "date_of_accident__lte") {
      return "bg-orange-600 text-white";
    } else if (val === "date_of_accident__gte") {
      return "bg-orange-600 text-white";
    } else if (val === "created_at__lte") {
      return "bg-green-600 text-white";
    } else if (val === "created_at__gte") {
      return "bg-green-600 text-white";
    } else if (val === "netpro_users_assigned") {
      return "bg-purple-600 text-white";
    } else if (val === "has_law_firm_cm") {
      return "bg-yellow-300";
    } else if (val === "law_firm") {
      return "bg-sky-600 text-white";
    } else if (val === "campaign__isnull") {
      return "bg-sky-600 text-white";
    } else if (val === "property_damage_severity__isnull") {
      return "bg-orange-600 text-white";
    } else if (val === "supervisor_needed_reason__isnull") {
      return "bg-orange-400 text-white";
    } else if (
      val === "running_total" ||
      val === "running_total__lte" ||
      val === "running_total__gte" ||
      val === "running_total__gt" ||
      val === "running_total__lt"
    ) {
      return "bg-sky-600 text-white";
    } else {
      return;
    }
  };

  const clearFilter = (val) => {
    setTrigger(!trigger);
    if (val === "progression_finished") {
      setProgressionFinished("");
    } else if (val === "liability_request_status") {
      setLiabilityRequest("");
    } else if (val === "overall_status__in") {
      setSelectedCaseStatuses([]);
      setActiveCaseStatuses([]);
    } else if (val === "legal_status__in") {
      setSelectedLegalStatuses([]);
      setActiveLegalStatuses([]);
    } else if (val === "liability_status") {
      setLiability("");
    } else if (val === "all_medical_docs_recieved") {
      setMedDocs("");
    } else if (val === "settled") {
      setSettled("");
    } else if (val === "accident_pictures_received") {
      setAccidentPictures("");
    } else if (val === "assigned_case_manager") {
      setAssignedCm("");
    } else if (val === "onboarded") {
      setOnboarded("");
    } else if (val === "police_report_received") {
      setPoliceReport("");
    } else if (val === "date_of_accident") {
      setDateOfIncident(null);
    } else if (val === "lost") {
      setLostStatus("");
    } else if (val === propertyDmgKey) {
      setPropertyDamage("");
      setPropertyDmgKey("property_damage_amount");
    } else if (val === "client__date_of_birth") {
      setClientDob(null);
    } else if (val === "involved_individuals") {
      setInvolvedIndividuals();
    } else if (val === "search") {
      setSearch("");
    } else if (val === "client_manager__isnull") {
      setClientManager(null);
    } else if (val === "assigned_users") {
      setSelectedPM(null);
    } else if (val === totalSettledAmtKey) {
      setTotalSettledAmount("");
      setTotalSettledAmtKey("actual_total_settlement_amount");
    } else if (val === "status__in") {
      setSelectedStatuses([]);
      setActiveStatuses([]);
    } else if (val === "has_law_firm_cm") {
      setNoLfUserAssigned(null);
    } else if (val === "date_of_accident__lte") {
      setDateOfIncidentTo(null);
    } else if (val === "date_of_accident__gte") {
      setDateOfIncidentFrom(null);
    } else if (val === "created_at__lte") {
      setDateCreatedTo(null);
    } else if (val === "created_at__gte") {
      setDateCreatedFrom(null);
    } else if (val === "updated_at__lte") {
      setCaseUpdatedTo(null);
    } else if (val === "updated_at__gte") {
      setCaseUpdatedFrom(null);
    } else if (val === "last_note__lte") {
      setNoteAddedTo(null);
    } else if (val === "last_note__gte") {
      setNoteAddedFrom(null);
    } else if (val === "last_appointment__lte") {
      setApptAddedTo(null);
    } else if (val === "last_appointment__gte") {
      setApptAddedFrom(null);
    } else if (val === runningTotalKey) {
      setRunningTotal("");
      setRunningTotalKey("running_total");
    } else if (val === "campaign__isnull") {
      setHasSource(null);
    } else if (val === "callback_time__isnull") {
      setHasCallback(null);
    } else if (val === "property_damage_severity__isnull") {
      setPdSeverityIsNull(null);
    } else if (val === "property_damage_severity") {
      setPdSeverity(null);
    } else if (val === "ordering") {
      setOrderingVal(null);
    } else if (val === TOTAL_SETTLEMENT_AMOUNT_MIN) {
      setTotalSettlementAmountMin(undefined);
      setTotalSettlementAmountMax(undefined);
    } else if (val === MEDICAL_LIMITS_AVAILABLE_MIN) {
      setMedicalLimitsAvailableMin(undefined);
      setMedicalLimitsAvailableMax(undefined);
    } else if (val === PROPERTY_DAMAGE_AMOUNT_MIN) {
      setPropertyDamageAmountMin(undefined);
      setPropertyDamageAmountMax(undefined);
    } else if (val === RUNNING_TOTAL_MIN) {
      setRunningTotalMin(undefined);
      setRunningTotalMax(undefined);
    } else {
      return;
    }
    resetPagination();
  };

  useEffect(() => {
    if (!sources) {
      return;
    } else {
      let sourcesObj = {};

      for (const item in sources) {
        sourcesObj[sources[item].pk] = sources[item].name;
      }

      setCaseSourceObj(sourcesObj);
    }
  }, [sources, setSources, accessToken, setCaseSourceObj]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  const resetPagination = () => {
    setPaginationUrl("");
    setCurrentPage(1);
  };

  const handleCaseStatusUpdate = (e) => {
    setCaseStatus(e.target.value);
    resetPagination();
  };

  const getLabelLiabilityRequest = useMemo(() => {
    return liabilityRequestStatusChoices.find(obj => obj.key === parseInt(liabilityRequest))?.label;
  }, [liabilityRequestStatusChoices, liabilityRequest])

  const getMaxHeightClass = useMemo(() => {
    if (isDashboardVersion) {
      if (dashboardV === "legacy") {
        return "md:max-h-[40vh]";
      }
      return "md:max-h-[90%] max-h-[85%]";
    }
    return "md:max-h-[90vh] max-h-[85vh]";
  }, [isDashboardVersion, dashboardV]);

  const generateSliderFilters = () => {
    if (!activeFilters) return null;

    return SLIDER_FILTER_KEYS.map((key) => {
      if (!activeFilters.includes(key)) return null;

      let label = "";
      switch (key) {
        case TOTAL_SETTLEMENT_AMOUNT_MIN:
          label = `Total Settlement Amount between ${formatter.format(
            totalSettlementAmountMin || 0
          )} - ${formatter.format(totalSettlementAmountMax || 0)}`;
          break;
        case MEDICAL_LIMITS_AVAILABLE_MIN:
          label = `Medical Limits Available between ${formatter.format(
            medicalLimitsAvailableMin || 0
          )} - ${formatter.format(medicalLimitsAvailableMax || 0)}`;
          break;
        case PROPERTY_DAMAGE_AMOUNT_MIN:
          label = `Property Dmg between ${formatter.format(
            propertyDamageAmountMin || 0
          )} - ${formatter.format(propertyDamageAmountMax || 0)}`;
          break;
        case RUNNING_TOTAL_MIN:
          label = `Running Total between ${formatter.format(
            runningTotalMin || 0
          )} - ${formatter.format(runningTotalMax || 0)}`;
          break;
        default:
          break;
      }
      if (!label) return null;
      return (
        <Chip
          key={key}
          className={`px-2 m-1 text-center rounded-lg shadow-inner ${filterBgColor(
            key
          )}`}
          onDelete={() => clearFilter(key)}
          label={label}
        />
      );
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`header-wrapper ${isDashboardVersion && dashboardV === "legacy"
          ? "xl:h-[50vh]"
          : "xl:h-full"
          } flex overflow-hidden lg:mt-0 mt-4`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div
          className={`shadow-lg text-center 
              ${isDashboardVersion
              ? !toggleFilterMenu
                ? "w-fit absolute bg-white z-10  h-full"
                : "w-[0px] hidden"
              : !toggleFilterMenu
                ? "lg:w-[30%] w-fit absolute lg:relative bg-white z-10 h-full"
                : "w-[0px] hidden"
            }`}
        >
          <div className="font-bold border-b border-gray text-lg pt-[16px] pb-[12px] px-4 flex justify-between">
            <h2>Sort Clients:</h2>
            {isDashboardVersion && !toggleFilterMenu ? (
              <Tooltip
                title={`${toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                  }`}
              >
                <Button
                  onClick={menuOpenClose}
                  className={`${toggleFilterMenu ? "hidden" : "rotate-180"}`}
                >
                  <ArrowForwardIos />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                  }`}
              >
                <Button
                  onClick={menuOpenClose}
                  className={`${toggleFilterMenu ? "" : "rotate-180 lg:hidden"
                    }`}
                >
                  <ArrowForwardIos />
                </Button>
              </Tooltip>
            )}
          </div>
          <List
            className={`overflow-y-auto pb-6 bg-white ${getMaxHeightClass}`}
          >
            <ListItem>
              <ClientSearchRangeSlider
                title="Actual Total Settlement Amount"
                minValue={totalSettlementAmountMin}
                maxValue={totalSettlementAmountMax}
                setMinValue={setTotalSettlementAmountMin}
                setMaxValue={setTotalSettlementAmountMax}
                formatter={formatter}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <ClientSearchRangeSlider
                title="Medical Limits Available"
                minValue={medicalLimitsAvailableMin}
                maxValue={medicalLimitsAvailableMax}
                setMinValue={setMedicalLimitsAvailableMin}
                setMaxValue={setMedicalLimitsAvailableMax}
                formatter={formatter}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <ClientSearchRangeSlider
                title="Property Damage Amount"
                minValue={propertyDamageAmountMin}
                maxValue={propertyDamageAmountMax}
                setMinValue={setPropertyDamageAmountMin}
                setMaxValue={setPropertyDamageAmountMax}
                formatter={formatter}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <ClientSearchRangeSlider
                title="Running Total"
                minValue={runningTotalMin}
                maxValue={runningTotalMax}
                setMinValue={setRunningTotalMin}
                setMaxValue={setRunningTotalMax}
                formatter={formatter}
                resetPagination={resetPagination}
              />
            </ListItem>
            {userRoles.permissions.includes("users.view_user") ? (
              <ListItem>
                <MultiSelectSearchV2
                  title="Assigned Users"
                  searchTitle="User Search"
                  selectedItems={assignedUsers}
                  setSelectedItems={setAssignedUsers}
                  setActiveItems={setActiveUsers}
                  activeItems={activeUsers}
                  resetPagination={resetPagination}
                  nameKey={"name"}
                  valueKey={"pk"}
                  apiUrl={`/api/users/all_assignable_users/?`}
                />
              </ListItem>
            ) : (
              ""
            )}
            {/* //!Need to add code to get nested values (ewwy) */}
            <ListItem>
              <MultiSelectSearchV2
                title="Related Cases"
                searchTitle="Related Cases"
                selectedItems={selectedInvolved}
                setSelectedItems={setSelectedInvolved}
                setActiveItems={setActiveInvolved}
                activeItems={activeInvolved}
                resetPagination={resetPagination}
                nameKey={"name"}
                valueKey={"pk"}
                apiUrl="/api/case/?"
              />
            </ListItem>
            <ListItem>
              <MultiSelectSearchV2
                title="Uploaded Document Type"
                searchTitle="Document Types"
                selectedItems={selectedDocTypes}
                setSelectedItems={setSelectedDocTypes}
                setActiveItems={setActiveDocTypes}
                activeItems={activeDocTypes}
                resetPagination={resetPagination}
                nameKey={"label"}
                valueKey={"key"}
                apiUrl="/api/field_options/?content_type=document&field_name=document_type&ordering=order&"
              />
            </ListItem>
            {userRoles.permissions.includes("users.view_entity") ? (
              <ListItem>
                <MultiSelectSearchV2
                  title="Entities"
                  searchTitle="Entities"
                  selectedItems={selectedEntities}
                  setSelectedItems={setSelectedEntities}
                  setActiveItems={setActiveEntities}
                  activeItems={activeEntities}
                  resetPagination={resetPagination}
                  nameKey={"name"}
                  valueKey={"pk"}
                  apiUrl="/api/entities/?is_related_to_law_firm=true&"
                />
              </ListItem>
            ) : (
              ""
            )}
            <ListItem>
              <MultiSelectSearchV2
                title="Incident State"
                searchTitle="States"
                selectedItems={selectedLocations}
                setSelectedItems={setSelectedLocations}
                setActiveItems={setActiveLocations}
                activeItems={activeLocations}
                resetPagination={resetPagination}
                nameKey={"label"}
                valueKey={"key"}
                orderingVal="label"
                apiUrl="/api/field_options/?content_type=case&field_name=incident_location&"
              />
            </ListItem>
            <ListItem>
              <MultiSelectSearchV2
                title="Incident not in State"
                searchTitle="States"
                selectedItems={selectedNotLocations}
                setSelectedItems={setSelectedNotLocations}
                setActiveItems={setActiveNotLocations}
                activeItems={activeNotLocations}
                resetPagination={resetPagination}
                nameKey={"label"}
                valueKey={"key"}
                orderingVal="label"
                apiUrl="/api/field_options/?content_type=case&field_name=incident_location&"
              />
            </ListItem>
            <ListItem>
              <MultiSelectSearchV2
                title="Not Uploaded Document Type"
                searchTitle="Document Types"
                selectedItems={selectedNotUpDocTypes}
                setSelectedItems={setSelectedNotUpDocTypes}
                setActiveItems={setActiveNotUpDocTypes}
                activeItems={activeNotUpDocTypes}
                resetPagination={resetPagination}
                nameKey={"label"}
                valueKey={"key"}
                apiUrl="/api/field_options/?content_type=document&field_name=document_type&ordering=order&"
              />
            </ListItem>
            {userRoles.permissions.includes("leads.view_all_campaigns") ? (
              <ListItem>
                <MultiSelectSearchV2
                  title="Campaign"
                  searchTitle="Campaign"
                  selectedItems={selectedSources}
                  setSelectedItems={setSelectedSources}
                  setActiveItems={setActiveSources}
                  activeItems={activeSources}
                  nameKey={"name"}
                  valueKey={"pk"}
                  resetPagination={resetPagination}
                  apiUrl="/api/leads/campaigns/?"
                />
              </ListItem>
            ) : (
              ""
            )}
            {userRoles.permissions.includes("users.view_userroles") ? (
              <>
                <ListItem>
                  <MultiSelectSearchV2
                    title="Assigned Roles"
                    searchTitle="Role"
                    selectedItems={selectedRoles}
                    setSelectedItems={setSelectedRoles}
                    setActiveItems={setActiveRoles}
                    activeItems={activeRoles}
                    nameKey={"name"}
                    valueKey={"pk"}
                    resetPagination={resetPagination}
                    apiUrl="/api/user_roles/?"
                  />
                </ListItem>
                <ListItem>
                  <MultiSelectSearchV2
                    title="Unassigned Roles"
                    searchTitle="Role"
                    selectedItems={selectedUnassignedRoles}
                    setSelectedItems={setSelectedUnassignedRoles}
                    setActiveItems={setActiveUnassignedRoles}
                    activeItems={activeUnassignedRoles}
                    nameKey={"name"}
                    valueKey={"pk"}
                    resetPagination={resetPagination}
                    apiUrl="/api/user_roles/?"
                  />
                </ListItem>
              </>
            ) : (
              ""
            )}
            {userRoles.permissions.includes("lawyer.view_lawfirm") ? (
              <ListItem>
                <MultiSelectSearchV2
                  title="Law Firm"
                  searchTitle="Law Firms"
                  selectedItems={selectedLawfirms}
                  setSelectedItems={setSelectedLawfirms}
                  setActiveItems={setActiveLawFirm}
                  activeItems={activeLawFirm}
                  nameKey={"name"}
                  valueKey={"pk"}
                  resetPagination={resetPagination}
                  apiUrl="/api/lawfirm/?"
                />
              </ListItem>
            ) : (
              ""
            )}
            <ListItem>
              <MultiSelectFilterV3
                title="Medical Status"
                responseArray={caseStatusOptions}
                selectedItems={selectedStatuses}
                setSelectedItems={setSelectedStatuses}
                setActiveItems={setActiveStatuses}
                activeItems={activeStatuses}
                nameKey="label"
                valueKey="key"
                resetPagination={resetPagination}
                searchTitle="Statuses"
              />
            </ListItem>
            <ListItem>
              <MultiSelectFilterV3
                title="Case Status"
                responseArray={overallStatusChoices}
                selectedItems={selectedCaseStatuses}
                setSelectedItems={setSelectedCaseStatuses}
                setActiveItems={setActiveCaseStatuses}
                activeItems={activeCaseStatuses}
                nameKey="label"
                valueKey="key"
                resetPagination={resetPagination}
                searchTitle="Statuses"
              />
              </ListItem>
              <ListItem>
              <MultiSelectFilterV3
                title="Legal Status"
                responseArray={legalStatusChoices}
                selectedItems={selectedLegalStatuses}
                setSelectedItems={setSelectedLegalStatuses}
                setActiveItems={setActiveLegalStatuses}
                activeItems={activeLegalStatuses}
                nameKey="label"
                valueKey="key"
                resetPagination={resetPagination}
                searchTitle="Statuses"
              />
            </ListItem>

            {userRoles.permissions.includes("lawyer.view_lawfirm") ? (
              <ListItem>
                <MultiSelectSearchV2
                  title="Lost Status"
                  searchTitle="Lost Codes"
                  selectedItems={selectedLost}
                  setSelectedItems={setSelectedLost}
                  setActiveItems={setActiveLost}
                  activeItems={activeLost}
                  nameKey={"reason"}
                  valueKey={"id"}
                  resetPagination={resetPagination}
                  apiUrl="/api/lost_case_options/?"
                />
              </ListItem>
            ) : (
              ""
            )}
            <ListItem>
              <DatePicker
                className="w-full ml-1 my-1 text-center"
                label="Date of Birth"
                value={!clientDob ? new Date() : clientDob}
                onChange={(newValue) => {
                  setClientDob(newValue);
                  resetPagination();
                }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
              {!clientDob ? (
                ""
              ) : (
                <Button
                  onClick={() => setClientDob(null)}
                  className="text-sm text-red-600 border-red-600  px-0 hover:bg-red-600 hover:text-white"
                >
                  X
                </Button>
              )}
            </ListItem>
            <ListItem>
              <DatePicker
                className="w-full ml-1 my-1 text-center"
                label="Date of Accident"
                value={!dateOfIncident ? new Date() : dateOfIncident}
                onChange={(newValue) => {
                  setDateOfIncident(newValue);
                  resetPagination();
                }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
              {!dateOfIncident ? (
                ""
              ) : (
                <Button
                  onClick={() => setDateOfIncident(null)}
                  className="text-sm text-red-600 border-red-600  px-0 hover:bg-red-600 hover:text-white"
                >
                  X
                </Button>
              )}
            </ListItem>
            <ListItem>
              <DateRange
                val="Date of Accident"
                dateFrom={dateOfIncidentFrom}
                dateTo={dateOfIncidentTo}
                setDateFrom={setDateOfIncidentFrom}
                setDateTo={setDateOfIncidentTo}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <DateRange
                val="Date Created"
                dateFrom={dateCreatedFrom}
                dateTo={dateCreatedTo}
                setDateFrom={setDateCreatedFrom}
                setDateTo={setDateCreatedTo}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <DateRange
                val="Date Updated"
                dateFrom={caseUpdatedFrom}
                dateTo={caseUpdatedTo}
                setDateFrom={setCaseUpdatedFrom}
                setDateTo={setCaseUpdatedTo}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <DateRange
                val="Last Note Added"
                dateFrom={noteAddedFrom}
                dateTo={noteAddedTo}
                setDateFrom={setNoteAddedFrom}
                setDateTo={setNoteAddedTo}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <DateRange
                val="Last Appointment Added"
                dateFrom={apptAddedFrom}
                dateTo={apptAddedTo}
                setDateFrom={setApptAddedFrom}
                setDateTo={setApptAddedTo}
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <AccordionSelectForm
                title="Liability Request"
                val={liabilityRequest}
                setVal={setLiabilityRequest}
                resetPagination={resetPagination}
                choicesArr={liabilityRequestStatusChoices}
              />
            </ListItem>
            <ListItem>
              <AccordionSelectForm
                title="Liability Status"
                val={liability}
                setVal={setLiability}
                resetPagination={resetPagination}
                choicesArr={liabilityStatusChoices}
              />
            </ListItem>
            <ListItem>
              <AccordionSelectForm
                title="PD Severity"
                val={pdSeverity}
                setVal={setPdSeverity}
                resetPagination={resetPagination}
                choicesArr={pdChoices}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setPdSeverityIsNull}
                value={pdSeverityIsNull}
                title="Needs PD Severity"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setHasCallback}
                value={hasCallback}
                customFalseLabel={"True"}
                customTrueLabel={"False"}
                title="Callback Scheduled"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setHasSource}
                value={hasSource}
                title="Source"
                customFalseLabel={"Progression"}
                customTrueLabel={"Non Progression"}
                resetPagination={resetPagination}
              />{" "}
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setNoLfUserAssigned}
                value={noLfUserAssigned}
                title="Has Law Firm CM"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setMedDocs}
                value={medDocs}
                title="Med Docs Received"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setAccidentPictures}
                value={accidentPictures}
                title="Accident Pictures"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setOnboarded}
                value={onboarded}
                title="Onboarded"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setPoliceReport}
                value={policeReport}
                title="Police Report"
                resetPagination={resetPagination}
              />
            </ListItem>
          </List>
        </div>

        <div className="w-full md:mx-4">
          <div className="flex justify-center mt-4">
            <Tooltip
              title={`${toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                }`}
            >
              <Button
                onClick={menuOpenClose}
                className={`${toggleFilterMenu ? "" : "rotate-180"}`}
              >
                <ArrowForwardIos />
              </Button>
            </Tooltip>
            <Tooltip title="Search">
              <TextField
                className="placeholder:text-center rounded-lg w-full mx-4 md:mx-0 text-sm"
                placeholder="Search"
                size="small"
                name="search"
                value={search}
                InputLabelProps={{ shrink: true }}
                label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                  resetPagination();
                }}
              />
            </Tooltip>
          </div>
          <div className="m-2">
            <CustomChip
              setActiveItems={setActiveUsers}
              activeItems={activeUsers}
              selectedItems={assignedUsers}
              setSelectedItems={setAssignedUsers}
              title="Users to case"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveLocations}
              activeItems={activeLocations}
              selectedItems={selectedLocations}
              setSelectedItems={setSelectedLocations}
              title="Incident States"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveNotLocations}
              activeItems={activeNotLocations}
              selectedItems={setSelectedNotLocations}
              setSelectedItems={setSelectedNotLocations}
              title="Incident not in States"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveEntities}
              activeItems={activeEntities}
              selectedItems={selectedEntities}
              setSelectedItems={setSelectedEntities}
              title="Entities"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveInvolved}
              activeItems={activeInvolved}
              selectedItems={selectedInvolved}
              setSelectedItems={setSelectedInvolved}
              title="Related cases"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveSources}
              activeItems={activeSources}
              selectedItems={selectedSources}
              setSelectedItems={setSelectedSources}
              title="Campaigns"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveLawFirm}
              activeItems={activeLawFirm}
              selectedItems={selectedLawfirms}
              setSelectedItems={setSelectedLawfirms}
              title="Law Firms"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveLost}
              activeItems={activeLost}
              selectedItems={selectedLost}
              setSelectedItems={setSelectedLost}
              title="Lost Codes"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveRoles}
              activeItems={activeRoles}
              selectedItems={selectedRoles}
              setSelectedItems={setSelectedRoles}
              title="Roles assigned to case"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveUnassignedRoles}
              activeItems={activeUnassignedRoles}
              selectedItems={selectedUnassignedRoles}
              setSelectedItems={setSelectedUnassignedRoles}
              title="Roles not assigned to case"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveDocTypes}
              activeItems={activeDocTypes}
              selectedItems={selectedDocTypes}
              setSelectedItems={setSelectedDocTypes}
              title="Documents Uploaded"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveNotUpDocTypes}
              activeItems={activeNotUpDocTypes}
              selectedItems={selectedNotUpDocTypes}
              setSelectedItems={setSelectedNotUpDocTypes}
              title="Documents Not Uploaded"
              resetPagination={resetPagination}
            />
            {generateSliderFilters()}
            {activeFilters.length > 0
              &&
              activeFilters
              .filter(filt => !SLIDER_FILTER_KEYS.includes(filt))  
              .map((filt, idx) => (
                <Chip
                  className={`px-2 m-1 text-center rounded-lg shadow-inner ${filterBgColor(
                    filt
                  )}`}
                  key={filt + idx}
                  onDelete={() => clearFilter(filt)}
                  label={
                    filt === "progression_finished"
                      ? `${progressionFinished === "true"
                        ? "Progression Finished: True"
                        : "Progression Finished: False"
                      }`
                      : filt === "campaign__isnull"
                        ? `${hasSource === "true"
                          ? "Source: Non Progression"
                          : "Source: Progression"
                        }`
                        : filt === "liability_request_status"
                          ? `Liability Request: ${getLabelLiabilityRequest}`
                          : filt === "overall_status__in"
                            ? `Case Status: ${activeCaseStatuses}`
                            : filt === "legal_status__in"
                              ? `Legal Status: ${activeLegalStatuses}`
                              : filt === "liability_status"
                                ? `Liability Status: ${liability}`
                                : filt === "all_medical_docs_recieved"
                                  ? `${medDocs === "true"
                                    ? "Med Docs Received: True"
                                    : "Med Docs Received: False"
                                  }`
                                  : filt === "settled"
                                    ? `${settled === "true"
                                      ? "Settled: True"
                                      : "Settled: False"
                                    }`
                                    : filt === "accident_pictures_received"
                                      ? `${accidentPictures === "true"
                                        ? "Accident Pics Received: True"
                                        : "Accident Pics Received: False"
                                      }`
                                      : filt === "assigned_case_manager"
                                        ? `Lawfirm CM: ${assignedCm}`
                                        : filt === "onboarded"
                                          ? `${onboarded === "true"
                                            ? "Onboarded: True"
                                            : "Onboarded: False"
                                          }`
                                          : filt === "police_report_received"
                                            ? `${policeReport === "true"
                                              ? "Police Report Received: True"
                                              : "Police Report Received: False"
                                            }`
                                            : filt === "date_of_accident"
                                              ? `DOI: ${!dateOfIncident
                                                ? ""
                                                : new Date(dateOfIncident).toLocaleDateString(
                                                  "en-CA"
                                                )
                                              }`
                                              : filt === "date_of_accident__lte"
                                                ? `DOI Before: ${!dateOfIncidentTo
                                                  ? ""
                                                  : new Date(dateOfIncidentTo).toLocaleDateString(
                                                    "en-CA"
                                                  )
                                                }`
                                                : filt === "date_of_accident__gte"
                                                  ? `DOI After: ${!dateOfIncidentFrom
                                                    ? ""
                                                    : new Date(dateOfIncidentFrom).toLocaleDateString(
                                                      "en-CA"
                                                    )
                                                  }`
                                                  : filt === "created_at__lte"
                                                    ? `Date Created Before: ${!dateCreatedTo
                                                      ? ""
                                                      : new Date(dateCreatedTo).toLocaleDateString(
                                                        "en-CA"
                                                      )
                                                    }`
                                                    : filt === "updated_at__lte"
                                                      ? `Last Updated Before: ${!caseUpdatedTo
                                                        ? ""
                                                        : new Date(caseUpdatedTo).toLocaleDateString(
                                                          "en-CA"
                                                        )
                                                      }`
                                                      : filt === "has_law_firm_cm"
                                                        ? `Law Firm Case Manager Assigned: ${noLfUserAssigned}`
                                                        : filt === "created_at__gte"
                                                          ? `Date Created After: ${!dateCreatedFrom
                                                            ? ""
                                                            : new Date(dateCreatedFrom).toLocaleDateString(
                                                              "en-CA"
                                                            )
                                                          }`
                                                          : filt === "updated_at__gte"
                                                            ? `Last Updated After: ${!caseUpdatedFrom
                                                              ? ""
                                                              : new Date(caseUpdatedFrom).toLocaleDateString(
                                                                "en-CA"
                                                              )
                                                            }`
                                                            : filt === "last_note__gte"
                                                              ? `Last Note Added After: ${!noteAddedFrom
                                                                ? ""
                                                                : new Date(noteAddedFrom).toLocaleDateString(
                                                                  "en-CA"
                                                                )
                                                              }`
                                                              : filt === "last_note__lte"
                                                                ? `Last Note Added Before: ${!noteAddedTo
                                                                  ? ""
                                                                  : new Date(noteAddedTo).toLocaleDateString(
                                                                    "en-CA"
                                                                  )
                                                                }`
                                                                : filt === "last_appointment__gte"
                                                                  ? `Last Appointment Added After: ${!apptAddedFrom
                                                                    ? ""
                                                                    : new Date(apptAddedFrom).toLocaleDateString(
                                                                      "en-CA"
                                                                    )
                                                                  }`
                                                                  : filt === "last_appointment__lte"
                                                                    ? `Last Appointment Added Before: ${!apptAddedTo
                                                                      ? ""
                                                                      : new Date(apptAddedTo).toLocaleDateString(
                                                                        "en-CA"
                                                                      )
                                                                    }`
                                                                    : filt === "lost"
                                                                      ? `Lost: ${lostStatus}`
                                                                      : filt === "client__date_of_birth"
                                                                        ? `Client DOB: ${!clientDob
                                                                          ? ""
                                                                          : new Date(clientDob).toLocaleDateString("en-CA")
                                                                        }`
                                                                        : filt === "involved_individuals"
                                                                          ? `Involved: ${involvedIndividuals}`
                                                                          : filt === "law_firm"
                                                                            ? `Law Firm: ${!lawfirmNames ? "" : lawfirmNames[selectedLawfirms]
                                                                            }`
                                                                            : filt === "search"
                                                                              ? `Search: ${search}`
                                                                              : filt === "client_manager"
                                                                                ? "CM"
                                                                                : filt === "callback_time__isnull"
                                                                                  ? `Callback Scheduled: ${hasCallback === "true" ? "false" : "true"
                                                                                  }`
                                                                                  : filt === "status__in"
                                                                                    ? `Medical Status: ${activeStatuses}`
                                                                                    : filt === "property_damage_severity__isnull"
                                                                                      ? `Needs PD Severity: ${pdSeverityIsNull}`
                                                                                      : filt === "property_damage_severity"
                                                                                        ? `PD Severity: ${pdDisplayOptions[pdSeverity]}`
                                                                                        : filt === "ordering"
                                                                                          ? `Ordered by: ${!orderingVal
                                                                                            ? ""
                                                                                            : orderingVal.charAt(0) === "-"
                                                                                              ? "Descending"
                                                                                              : "Ascending"
                                                                                          } ${!orderingVal
                                                                                            ? ""
                                                                                            : orderingVal === "created_at" ||
                                                                                              orderingVal === "-created_at"
                                                                                              ? "Created At"
                                                                                              : orderingVal === "scheduled_appts" ||
                                                                                                orderingVal === "-scheduled_appts"
                                                                                                ? "Scheduled Appointments"
                                                                                                : "Date of Accident"
                                                                                          }`
                                                                                          : ""
                  }
                ></Chip>
              ))}
          </div>
          <div className="xl:h-full overflow-hidden">
            <div className="flex justify-between my-2">
              <p className="pl-5 py-4">
                Showing: {resultCount.current} / {resultCount.total}
              </p>
              {/* {userType === "admin" ? (
                <Button
                  variant="outlined"
                  color="success"
                  onClick={printList}
                  className="mt-2 hover:bg-purple-600 hover:text-white text-purple-600 border-purple-600 float-left ml-6 z-0 relative mr-2"
                >
                  {" "}
                  Print
                </Button>
              ) : (
                ""
              )} */}
            </div>
            <div className="flex md:px-4 text-center py-2 border-b border-1 border-gray l:justify-start justify-evenly">
              <div className={`${columnClassesLg} font-bold`}>
                <h2 className="md:block hidden">CLIENT NAME</h2>
                <h2 className="md:hidden">NAME</h2>
              </div>
              <div className={`${columnClassesSmall} font-bold`}>
                <h2>
                  DOA{" "}
                  <OrderingArrow
                    val={"date_of_accident"}
                    orderingVal={orderingVal}
                    setOrderingVal={setOrderingVal}
                  />
                </h2>
              </div>
              <div className={`${columnClassesSmall} font-bold`}>
                <h2>MEDICAL STATUS</h2>
              </div>
              <div className={`${columnClassesSmall} font-bold`}>
                <h2>CASE STATUS</h2>
              </div>
              {!isLawFirm && (
                <div className={`${columnClasses} font-bold`}>
                  <h2>LAW FIRM</h2>
                </div>
              )}
              {!isProvider &&
                <div className={`${columnClassesSmall} font-bold`}>
                  <h2>MEDICAL LIMITS</h2>
                </div>
              }
              <div className={`${columnClassesSmall} font-bold`}>
                <h2>CURRENT BALANCE</h2>
              </div>
              <div className={`${columnClassesSmall} font-bold`}>
                <h2>RUNNING TOTAL</h2>
              </div>
            </div>
            <List
              data-testid="client-list"
              className={`${
                isDashboardVersion && dashboardV === "legacy"
                  ? "lg:max-h-[25vh]"
                  : "max-h-[75vh]"
              } overflow-y-auto h-[calc(100vh-428px)]`}
            >
              {loading ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {!responseData
                    ? ""
                    : responseData.map((client, index) => (
                        <ClientListItemV2
                          key={index}
                          client={client}
                          index={index}
                          currentPage={currentPage}
                          columnClasses={columnClasses}
                          columnClassesLg={columnClassesLg}
                          columnClassesSmall={columnClassesSmall}
                        />
                    ))}
                </>
              )}
              <ListItem
                className={`justify-center py-10`}
                data-testid="client-list-item"
              >
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
        {/* PRINTABLE SECTION */}
        <PrintableClientList
          responseData={!printableList ? "" : printableList}
          componentRef={componentRef}
          loading={loading}
          activeFilters={true}
          sourcesObj={caseSourceObj}
          lostNames={lostNames}
          activeLawFirm={activeLawFirm}
          activeSources={activeSources}
          activeLost={activeLost}
          title={
            !activeFilters
              ? ""
              : activeFilters
                .map((filt, idx) =>
                  filt === "progression_finished"
                    ? `${progressionFinished === "true"
                      ? "Progression Finished: True"
                      : "Progression Finished: False"
                    }`
                    : filt === "liability_request_status"
                      ? `Liability Request: ${getLabelLiabilityRequest}`
                      : filt === "actual_case_status"
                        ? `Case Status: ${activeCaseStatuses}`
                        : filt === "legal_status__in"
                          ? `Legal Status: ${activeLegalStatuses}`
                          : filt === "liability_status"
                            ? `Liability Status: ${liability}`
                            : filt === "all_medical_docs_recieved"
                              ? `${medDocs === "true"
                                ? "Med Docs Received: True"
                                : "Med Docs Received: False"
                              }`
                              : filt === "settled"
                                ? `${settled === "true"
                                  ? "Settled: True"
                                  : "Settled: False"
                                }`
                                : filt === "accident_pictures_received"
                                  ? `${accidentPictures === "true"
                                    ? "Accident Pics Received: True"
                                    : "Accident Pics Received: False"
                                  }`
                                  : filt === "assigned_case_manager"
                                    ? `Lawfirm CM: ${assignedCm}`
                                    : filt === "onboarded"
                                      ? `${onboarded === "true"
                                        ? "Onboarded: True"
                                        : "Onboarded: False"
                                      }`
                                      : filt === "police_report_received"
                                        ? `${policeReport === "true"
                                          ? "Police Report Received: True"
                                          : "Police Report Received: False"
                                        }`
                                        : filt === "date_of_accident"
                                          ? `DOI: ${!dateOfIncident
                                            ? ""
                                            : new Date(dateOfIncident).toLocaleDateString(
                                              "en-CA"
                                            )
                                          }`
                                          : filt === "date_of_accident__lte"
                                            ? `DOI Before: ${!dateOfIncidentTo
                                              ? ""
                                              : new Date(dateOfIncidentTo).toLocaleDateString(
                                                "en-CA"
                                              )
                                            }`
                                            : filt === "date_of_accident__gte"
                                              ? `DOI After: ${!dateOfIncidentFrom
                                                ? ""
                                                : new Date(dateOfIncidentFrom).toLocaleDateString(
                                                  "en-CA"
                                                )
                                              }`
                                              : filt === "date_created__lte"
                                                ? `Date Created Before: ${!dateCreatedTo
                                                  ? ""
                                                  : new Date(dateCreatedTo).toLocaleDateString(
                                                    "en-CA"
                                                  )
                                                }`
                                                : filt === "has_law_firm_cm"
                                                  ? `Law Firm Case Manager Assigned: ${noLfUserAssigned}`
                                                  : filt === "created_at__gte"
                                                    ? `Date Created After: ${!dateCreatedFrom
                                                      ? ""
                                                      : new Date(dateCreatedFrom).toLocaleDateString(
                                                        "en-CA"
                                                      )
                                                    }`
                                                    : filt === "lost"
                                                      ? `Lost: ${lostStatus}`
                                                      : filt === "property_damage_amount"
                                                        ? `Property Dmg: ${formatter.format(propertyDamage)}`
                                                        : filt === "property_damage_amount__lt"
                                                          ? `Property Dmg less than ${formatter.format(
                                                            propertyDamage
                                                          )}`
                                                          : filt === "property_damage_amount__lte"
                                                            ? `Property Dmg less than or equal to ${formatter.format(
                                                              propertyDamage
                                                            )}`
                                                            : filt === "property_damage_amount__gt"
                                                              ? `Property Dmg greater than ${formatter.format(
                                                                propertyDamage
                                                              )}`
                                                              : filt === "property_damage_amount__gte"
                                                                ? `Property Dmg greater than or equal to ${formatter.format(
                                                                  propertyDamage
                                                                )}`
                                                                : filt === "property_damage_amount__isnull"
                                                                  ? `Property Dmg - No Value`
                                                                  : filt === "actual_total_settlement_amount"
                                                                    ? `Total Settled Amount: ${formatter.format(
                                                                      totalSettledAmount
                                                                    )}`
                                                                    : filt === "actual_total_settlement_amount__lt"
                                                                      ? `Total Settled Amount less than ${formatter.format(
                                                                        totalSettledAmount
                                                                      )}`
                                                                      : filt === runningTotalKey
                                                                        ? `Running total ${formatter.format(runningTotal)}`
                                                                        : filt === "actual_total_settlement_amount__lte"
                                                                          ? `Total Settled Amount less than or equal to ${formatter.format(
                                                                            totalSettledAmount
                                                                          )}`
                                                                          : filt === "actual_total_settlement_amount__gt"
                                                                            ? `Total Settled Amount greater than ${formatter.format(
                                                                              totalSettledAmount
                                                                            )}`
                                                                            : filt === "actual_total_settlement_amount__gte"
                                                                              ? `Total Settled Amount greater than or equal to ${formatter.format(
                                                                                totalSettledAmount
                                                                              )}`
                                                                              : filt === "actual_total_settlement_amount__isnull"
                                                                                ? `Total Settled Amount - No Value`
                                                                                : filt === "client__date_of_birth"
                                                                                  ? `Client DOB: ${!clientDob
                                                                                    ? ""
                                                                                    : new Date(clientDob).toLocaleDateString("en-CA")
                                                                                  }`
                                                                                  : filt === "involved_individuals"
                                                                                    ? `Involved: ${involvedIndividuals}`
                                                                                    : filt === "law_firm"
                                                                                      ? `Law Firm: ${!lawfirmNames ? "" : lawfirmNames[selectedLawfirms]
                                                                                      }`
                                                                                      : filt === "search"
                                                                                        ? `Search: ${search}`
                                                                                        : filt === "client_manager"
                                                                                          ? "CM"
                                                                                          : filt === "status__in"
                                                                                            ? `Medical Status: ${activeStatuses}`
                                                                                            : filt === "callback_time__isnull"
                                                                                              ? `Callback Scheduled: ${hasCallback === "true" ? "false" : "true"
                                                                                              }`
                                                                                              : filt === "campaign__isnull"
                                                                                                ? `${hasSource === "true"
                                                                                                  ? "Source: Non Progression"
                                                                                                  : "Source: Progression"
                                                                                                }`
                                                                                                : filt === "ordering"
                                                                                                  ? `Ordered by: ${!orderingVal
                                                                                                    ? ""
                                                                                                    : orderingVal.charAt(0) === "-"
                                                                                                      ? "Descending"
                                                                                                      : "Ascending"
                                                                                                  } ${!orderingVal
                                                                                                    ? ""
                                                                                                    : orderingVal === "created_at" ||
                                                                                                      orderingVal === "-created_at"
                                                                                                      ? "Created At"
                                                                                                      : "Date of Accident"
                                                                                                  }`
                                                                                                  : ""
                )
                .join(", ")
          }
        />
      </div>
    </LocalizationProvider>
  );
}
