import {Alert, AlertTitle, Button, Dialog, List, ListItem,} from "@mui/material";
import React from "react";

export default function ResponseModal({
                                        title,
                                        description,
                                        openBool,
                                        setOpenBool,
                                        isError,
                                        errorMessage,
                                        handleCloseFunc,
                                      }) {

  const errorMessages = Array.isArray(errorMessage) ? errorMessage : [errorMessage];

  const renderErrorContent = () => {
    if (!errorMessages.length || !errorMessages[0]) {
      return "Please try again";
    }

    return (
      <List>
        {errorMessages.map((issue, index) => (
          <ListItem key={index} value={issue}>
            {issue}
          </ListItem>
        ))}
      </List>
    );
  };
  return (
    <Dialog open={openBool}>
      <Alert
        variant="outlined"
        severity={isError ? "error" : "success"}
        role={isError ? "error" : "alert"}
      >
        <AlertTitle>{title}</AlertTitle>
        {isError ? renderErrorContent() : <p>{description}</p>}
      </Alert>
      <Button
        onClick={() =>
          !handleCloseFunc ? setOpenBool(false) : handleCloseFunc()
        }
      >
        OK
      </Button>
    </Dialog>
  );
}
