import React, { useContext } from "react";
import {
  setCurrentPage,
  setOffset,
} from "../state/actions";
import { Stack } from "@mui/material";
import { LawFirmsStateContext, LawFirmsDispatchContext } from "../context/LawFirmsProvider";
import PaginationControlsV2 from "../../../../../../../../../global/PaginationControlsV2";

const Pagination = () => {
  const state = useContext(LawFirmsStateContext);
  const dispatch = useContext(LawFirmsDispatchContext);

  const handleChange = (event, value) => {
    setCurrentPage(dispatch, value);
    if (value === 1) {
      setOffset(dispatch, state.resLimit);
    }
    setOffset(dispatch, state.resLimit * (value - 1));

  };
  return (
    <div id="Pagination">
      {!state.fetchIsLoading && (
        <div className="flex justify-center flex-row">
          <div className={`flex-none justify-start py-2 px-0 float-left`}>
            <span className="text-sm">
              Showing:{" "}
              {state.resultCount.current + " / " + state.resultCount.total}
            </span>
          </div>
          <div className={`flex justify-center py-2 md:px-0 px-0 w-full`}>
            <Stack alignItems={"center"}>
              <PaginationControlsV2
                alignment={"center"}
                resultCount={state.resultCount.total}
                handleChange={handleChange}
                currentPage={state.currentPage}
                resultLimit={state.resLimit}
              />
            </Stack>
          </div>
        </div>
      )}
    </div>
  );
};
export default Pagination;
