import TabHeader from "./TabHeader";
import TabBody from "./TabBody";
import { useState } from "react";

export default function TabPanel({ tabList }) {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="flex-1 h-full flex flex-col items-stretch transparent">
      <TabHeader
        tabList={tabList}
        tabValue={tabValue}
        handleChange={handleChange}
      />
      <TabBody tabList={tabList} tabValue={tabValue}/>
    </div>
  );
}
