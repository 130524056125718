import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import ApiRequestErrorHandler from "../../../../../../../../../global/ApiRequestErrorHandler";
import DataContext from "../../../../../../../../../../context/DataContext";
import { user } from "../../../../../../../../../api/endpoints/user";
import Search from "./Search";
import { useDebouncedEffect } from "../../../../../../../../../hooks/useDebounceEffect";
import { GenerateQueryParameterString } from "../../../../../../../../../global/GenerateQueryParamString";
import PaginationControlsV2 from "../../../../../../../../../global/PaginationControlsV2";
import { Sort } from "@mui/icons-material";
import PermissionWrapper from "../../../../../../../../../global/PermissionWrapper";

const AddTeams = ({ selectedUserId, closeModal, fetchNewData }) => {
  const resLimit = 25;
  const [shouldInitFetchData, setShouldInitFetchData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchSuccess, setfetchSuccess] = useState(true);
  const [fetchError, setfetchError] = useState(true);
  const [resultCount, setResultCount] = useState({ total: 0, current: 0 });
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchDelayed, setSearchDelayed] = useState("");
  const [filterParamsList, setFilterParamsList] = useState("");
  const [lawFirmTeams, setLawFirmTeams] = useState([]);
  const [checked, setChecked] = useState([]);
  const { userRoles, accessToken } = useContext(DataContext);

  const scrollRef = useRef(null);
  
    const scrollToTop = () => {
      scrollRef.current.scroll({
        top: 0,
      });
    };

  useEffect(() => {
    let active = true;
    if (active) {
      let paramObj = {
        ...(searchDelayed ? { search: searchDelayed } : {}),
        ...(offset ? { offset: offset } : {}),
      };
      let filtStr = GenerateQueryParameterString(paramObj, false);
      setFilterParamsList(filtStr);
      setShouldInitFetchData(true);
    }
    return () => {
      active = false;
    };
  }, [searchDelayed, offset]);

  const fetchLawFirmTeams = useCallback(() => {
    setIsLoading(true);
    setShouldInitFetchData(false);
    user
      .getLawFirmTeamsUserIsNotAMemberOf(
        selectedUserId,
        filterParamsList,
        accessToken
      )
      .then((res) => {
        setResultCount({
          total: res.count,
          current: res.results.length,
        });
        setLawFirmTeams(res.results);
        return res;
      })
      .then((res) => {
        setfetchSuccess(true);
        setIsLoading(false);

        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(errArr);
        setIsLoading(false);
      });
  }, [accessToken, selectedUserId, filterParamsList]);

  const handleToggle = (value) => (event) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    let active = true;
    if (active) {
      if (shouldInitFetchData) {
        fetchLawFirmTeams();
      }
    }
    return () => {
      active = false;
    };
  }, [fetchLawFirmTeams, shouldInitFetchData]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setOffset(resLimit);
    }
    setOffset(resLimit * (value - 1));
    scrollToTop(); 
  };

  useDebouncedEffect(
    () => {
      if (search) {
        setSearchDelayed(search);
      }
    },
    [search],
    800
  );
  const handleSave = () => {
    let selectedLawFirm = checked.map((item) => ({
      user: selectedUserId,
      team: item.id,
    }));

    user
      .addUserToLawFirmTeams(selectedLawFirm, accessToken)
      .then((res) => {
        setfetchSuccess(true);
        setIsLoading(false);
        return res;
      })
      .then((res) => {
        closeModal();
        fetchNewData();
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(errArr);
        setIsLoading(false);
      });
  };
  return (
    <div id="Content-Add-Teams" data-testid="Content-Add-Teams">
      <div className="text-xl text-center">Add Teams</div>
      <Search currentSearchValue={search} setSearchState={setSearch} />
      <List className="p-0 items-start w-[400px] h-[25vh] overflow-y-auto" ref={scrollRef}>
        {checked.length > 0 && (
          <ListItem className="block border-b border-black px-0 mb-4">
            <span className=" py-1 text-[16px] w-full">
              <Sort />
              &nbsp; Selected Teams
            </span>
            <List>
              {checked.length === 0
                ? "No Selected Teams"
                : checked.map((team) => {
                    const labelId = `checkbox-list-label-${team}`;
                    return (
                      <ListItem key={team.id} className="py-0">
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(team)}
                          className="py-0"
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.includes(team)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={<div>{team.law_firm.name}</div>}
                            secondary={
                              <div className="text-xs">
                                {team.law_firm.address}
                              </div>
                            }
                          ></ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
            </List>
          </ListItem>
        )}
        {lawFirmTeams && lawFirmTeams.length > 0 ? (
          lawFirmTeams.map((team) => {
            const labelId = `checkbox-list-label-${team}`;
            return (
              <ListItem key={team.id} className="py-0">
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(team)}
                  className="py-0"
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.includes(team)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={<div>{team.law_firm.name}</div>}
                    secondary={
                      <div className="text-xs">{team.law_firm.address}</div>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })
        ) : (
          <ListItem className="p-0">
            <ListItemButton role={undefined} dense>
              <ListItemText
                id={"No-Teams-Available"}
                primary={"No Teams Available"}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <div className={`flex justify-center py-2 md:px-0 px-0 w-full`}>
        <Stack alignItems={"center"}>
          <PaginationControlsV2
            alignment={"center"}
            resultCount={resultCount.total}
            handleChange={handleChange}
            currentPage={currentPage}
            resultLimit={25}
          />
        </Stack>
      </div>
      <PermissionWrapper
        permission={userRoles.permissions.includes("users.add_team")}
      >
        <Button
          className="m-2 capitalize"
          onClick={() => handleSave()}
          disabled={checked.length === 0}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </PermissionWrapper>
    </div>
  );
};
export default AddTeams;
