import React from "react";
import { TextField } from "@mui/material";

const Search = ({ currentSearchValue, setSearchState }) => {
  return (
    <div className="my-6">
      <TextField
        className="placeholder:text-center rounded-lg w-full text-sm"
        placeholder={"Law Firm Search"}
        size="small"
        name="lawfirm-teams-search"
        value={currentSearchValue}
        InputLabelProps={{ shrink: true }}
        label="Law Firm Search"
        onChange={(event) => {
          let searchVal = event.target.value;
          searchVal = searchVal.length === 0 ? " " : searchVal;
          setSearchState(searchVal);
        }}
      />
    </div>
  );
};
export default Search;
