import { AddAPhoto, LockReset, Login, PersonAdd } from '@mui/icons-material';
import { IconButton, List, ListItem, Tooltip, Avatar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ModalShell from '../../../../../global/ModalShell';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ImageUpload from './ImageUpload';
import ResponseModal from '../../../../../global/ResponseModal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DataContext from '../../../../../../context/DataContext';
import axios from '../../../../../api/axios';
import EditProfileDetails from './EditProfileDetails';
import LanguagePreferences from '../LanguagePreferences';
import ApiRequestErrorHandler from '../../../../../global/ApiRequestErrorHandler';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GeneralUserInfo from './GeneralUserInfo/GeneralUserInfo';
import AutoAssignment from './AutoAssignment/AutoAssignment';
import PropTypes from 'prop-types';
import { OrganizationAndPermissions } from "./OrganizationAndPermissions";

export default function ProfileInfo({
  userInfo,
  trigger,
  setTrigger,
  isDrawer,
  reloadTrigger,
  setReloadTrigger,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [showUploadButton, setShowUploadButton] = useState("");
  const [uploadProfileImage, setUploadProfileImage] = useState(false);
  const [editLanguages, setEditLanguages] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [coordinates, setCoordinates] = useState();
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [contentUpdated, setContentUpdated] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDOB] = useState("");
  const [languageObj, setLanguageObj] = useState("");


  const handleUpdateInfo = () => {
    let addressMapped;

    if (address === null) {
      addressMapped = { address: null };
    } else if (
      (address.id && userInfo.address && address.id === userInfo.address.id) ||
      address === ''
    ) {
      addressMapped = {};
    } else {
      addressMapped = { address: address };
    }

    let patchedUserInfo = {
      ...(fullName === '' ? {} : { name: fullName }),
      ...(email === '' ? {} : { email: email }),
      ...(phone === '' ? {} : { phone: phone }),
      ...addressMapped,
      ...(dob === ''
        ? {}
        : { date_of_birth: new Date(dob).toISOString().substring(0, 10) })
    };

    axios
      .patch(`/api/users/${userInfo.pk}/`, patchedUserInfo, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function () {
        setContentUpdated(true);
        setEditProfile(false);
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setErrorArray(errArr);
        setOpenError(true);
      });
  };

  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_language")) {
      axios
        .get(`/api/utilities/language/?ordering=created_at`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let obj = {};

          for (const item in data) {
            obj[data[item].pk] = data[item];
          }
          setLanguageObj(obj);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    } else {
      return;
    }
  }, [accessToken, userRoles.permissions, setLanguageObj]);

  const handleCloseEdit = () => {
    setFullName("");
    setEmail("");
    setAddress("");
    setCoordinates("");
    setPhone("");
    setDOB("");
    setEditProfile(false);
  };

  const handleCloseSuccessModal = () => {
    setTrigger(!trigger);
    setContentUpdated(false);
    if (reloadTrigger === true || reloadTrigger === false) {
      setTrigger(!trigger);
      setReloadTrigger(!reloadTrigger);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {!userInfo ? (
        ""
      ) : (
        <div
          className={`bg-white flex flex-col justify-start shadow-lg p-4 ${
            isDrawer
              ? 'xl:w-full'
              : 'xl:w-4/12 xl:mr-6'
          }`}
        >
          <div className="flex my-5">
            <div className="w-4/12 flex justify-center">
              <button
                className="relative w-[125px] h-[125px]"
                onMouseEnter={() => setShowUploadButton(true)}
                onMouseLeave={() => setShowUploadButton(false)}
              >
                <Avatar
                  className="w-full h-full text-black bg-black"
                  alt=""
                  src={userInfo.profile_picture || null}
                >
                  {!userInfo.profile_picture && (
                    <AccountCircleIcon className="w-[155px] h-[155px] bg-white" />
                  )}
                </Avatar>
                {showUploadButton && (
                  <div className="absolute rounded-full bottom-0 bg-[#ffffff9e] w-[125px] h-[125px] flex justify-center items-center z-10">
                    <Tooltip title="Update profile photo">
                      <IconButton onClick={() => setUploadProfileImage(true)}>
                        <AddAPhoto className="text-[40px]" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </button>
            </div>
            <div className="my-2 w-7/12">
              <p className="text-center text-3xl mb-5">{userInfo?.name}</p>
              <div className="grid gap-x-5 gap-y-1">
                <div className="grid grid-cols-2">
                  <p className="text-center">{userInfo?.entity?.name}</p>
                  <p className="text-center">{userInfo?.department_name}</p>
                </div>
                <div className="grid grid-cols-2">
                  <p className="text-center">{userInfo?.title}</p>
                  <p
                    className={`text-center text-[1rem] ${
                      userInfo?.is_active ? 'bg-green-600' : 'bg-red-600'
                    } text-white rounded-md w-fit px-2 m-auto`}
                  >
                    {userInfo?.is_active ? 'Active' : 'Inactive'}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <GeneralUserInfo userInfo={userInfo} languageObj={languageObj}/>
          {/* <AutoAssignment userInfo={userInfo} /> */}
          <OrganizationAndPermissions userInfo={userInfo} trigger={trigger} />
          <div className="border-t border-gray pt-2 relative">
            <div className="flex justify-between items-center pb-2">
              <h3 className="font-bold text-[18px]">Account Activity</h3>
              <div>&nbsp;</div>
            </div>
            <List className="p-0">
              <ListItem>
                {" "}
                <Tooltip title="Last Login" placement="right">
                  <Login />
                </Tooltip>
                &nbsp;
                {!userInfo.last_login
                  ? "Not entered"
                  : new Date(userInfo.last_login).toLocaleString()}
              </ListItem>
              <ListItem>
                {" "}
                <Tooltip title="Password Updated" placement="right">
                  <LockReset />
                </Tooltip>
                &nbsp;
                {!userInfo.password_updated_at
                  ? "Not entered"
                  : new Date(userInfo.password_updated_at).toLocaleString()}
              </ListItem>
              <ListItem>
                {" "}
                <Tooltip title="Date Joined" placement="right">
                  <PersonAdd />
                </Tooltip>
                &nbsp;
                {!userInfo.date_joined
                  ? "Not entered"
                  : new Date(userInfo.date_joined).toLocaleString()}
              </ListItem>
            </List>
          </div>
          <ModalShell
            open={editProfile}
            setOpen={setEditProfile}
            customCloseFunc={handleCloseEdit}
            color="orange"
            title="Edit Profile Details"
          >
            <EditProfileDetails
              userInfo={userInfo}
              handleUpdateInfo={handleUpdateInfo}
              fullName={fullName}
              setFullName={setFullName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              address={address}
              setAddress={setAddress}
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              dob={dob}
              setDOB={setDOB}
            />
          </ModalShell>
          <ModalShell
            open={uploadProfileImage}
            setOpen={setUploadProfileImage}
            color="#1976d2"
            title="Upload Profile Image"
          >
            <ImageUpload
              user={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              setUploadProfileImage={setUploadProfileImage}
              uploadProfileImage={uploadProfileImage}
              reloadTrigger={reloadTrigger}
              setReloadTrigger={setReloadTrigger}
            />
          </ModalShell>
          <ModalShell
            open={editLanguages}
            setOpen={setEditLanguages}
            color="#1976d2"
            title="Edit Language Preferences"
          >
            <LanguagePreferences
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              languageObj={languageObj}
            />
          </ModalShell>

          <ResponseModal
            title="Information Updated"
            description="Info Successfully updated for account"
            openBool={contentUpdated}
            setOpenBool={setContentUpdated}
            handleCloseFunc={handleCloseSuccessModal}
          />
          <ResponseModal
            title="Error Updating User Data"
            isError={true}
            openBool={openError}
            setOpenBool={setOpenError}
            errorMessage={errorArray}
          />
        </div>
      )}
    </LocalizationProvider>
  );
}

ProfileInfo.propTypes = {
  userInfo: PropTypes.object,
  trigger: PropTypes.any,
  setTrigger: PropTypes.any,
  isDrawer: PropTypes.any,
  reloadTrigger: PropTypes.any,
  setReloadTrigger: PropTypes.any
};
